import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import LogoutIcon from "@material-ui/icons/ExitToApp";
import classnames from "classnames";
import styles from "../../../theme/styles";
import DrawerMenuItem from "../AppMenuItem";
import { MenuList, MenuItem, Badge } from '@material-ui/core';
import { Avatar } from "@material-ui/core";
import UrlBuilderUtil from "../../../utils/projectBased/UrlBuilderUtil";
import BusinessIcon from '@material-ui/icons/Business';
import { connect } from "react-redux";
import objectPath from "object-path";
import UsersUtil from '../../../utils/projectBased/UsersUtil';
import TranslatorUtil from '../../../utils/TranslatorUtil';
import AccessUtil from '../../../utils/projectBased/AccessUtil';
import DashboardIcon from '@material-ui/icons/Dashboard';


class MobileDrawer extends React.Component {
 
 
  renderMenuLinks = (items, showIcon) => {
    const {
      userInfo,
    } = this.props;
    
    return items.map((m, index) => {
      return <DrawerMenuItem menuItem={m} key={index} showIcon={showIcon} userInfo={userInfo} />
    })
  }

  renderSelectedProjectMenuItem = (userInfo) => {
    const {
      selectedProject
    } = this.props;
    if(selectedProject){
      var selectedProjectMenuItem = {
        label: selectedProject.name,
        path: UrlBuilderUtil.getProjectDashboard(selectedProject.id),
        showIcon: true,
        icon: <BusinessIcon />
        
      }
      return <DrawerMenuItem menuItem={selectedProjectMenuItem} showIcon={true} userInfo={userInfo} />;
    }
    return null;
  }

  renderProfileMenuItem = (userInfo) => {
    const {
      classes
    } = this.props;
    if(userInfo){
      var profileMenuItem = {
        label: UsersUtil.getUserDisplayNameByUserInfo(userInfo),
        path: UrlBuilderUtil.getProfileEdit(),
        showIcon: true,
        icon: <Avatar className={classes.menuItemAvatar} src={UsersUtil.getAvatarUrlByAvatarId(objectPath.get(userInfo,'profile.avatar'))} />
      }

      return <DrawerMenuItem menuItem={profileMenuItem} showIcon={true} userInfo={userInfo} />;
    }
    return null;
  }

  renderCompanyMenuItem = (userInfo) => {
    if(AccessUtil.isCompanyAdmin()){
      var companyMenuItem = {
        label: TranslatorUtil.t('company'),
        path: UrlBuilderUtil.getCompany(),
        showIcon: true,
        icon: <BusinessIcon />
      }

      return <DrawerMenuItem menuItem={companyMenuItem} showIcon={true} userInfo={userInfo} />;
    }
    return null;
  }


  renderDashboardMenuItem = (userInfo) => {
    const {
      notifications
    } = this.props;
    var unreadCount = notifications.filter(x => !x.read).length;
      var menuItem = {
          label: "Dashboard",
          path: '/dashboard/',
          showIcon: true,
          icon: (unreadCount > 0 ? <Badge className="badge zoominout-badge" badgeContent={unreadCount} color="error"><DashboardIcon /></Badge> : <DashboardIcon />)
          
      };

      return <DrawerMenuItem menuItem={menuItem} showIcon={true} userInfo={userInfo} />;
    
  }

  render () {
    const { classes, handleDrawerClose, handleLogout, userInfo, menu, drawerOpen } = this.props;

    return <Drawer anchor="top" variant="persistent"
      open={drawerOpen} onClose={handleDrawerClose}
      classes={{
        root: classnames(classes.drawerRoot),
        paper: classnames(classes.drawerPaper, classes.mobileDrawer, 'mobileDrawer')
      }}
      >
        <div
          role="button"
        >
        
        
          <MenuList>
          <Divider />
          <Divider />
              {this.renderSelectedProjectMenuItem(userInfo)}
              <Divider />
              {this.renderDashboardMenuItem(userInfo)}
              {this.renderMenuLinks(menu.primaryMenu, true)}
              <Divider />
              {this.renderMenuLinks(menu.secondaryMenu, true)}
              <Divider />
              {this.renderProfileMenuItem(userInfo)}
              {this.renderCompanyMenuItem(userInfo)}
              <Divider />
              <MenuItem onClick={handleLogout} className={classnames(classes.menuItemText, classes.menuItemTextBtn)}>
                  <LogoutIcon fontSize="small" className={classes.menuItemIcon} />
                  {TranslatorUtil.t('Logout')}
              </MenuItem>
            </MenuList>
        </div>
      </Drawer>
  }
  
}



const mapStateToProps = (state) => ({
  //force rerender on location and dashboard data change
 routerLocations: state.routerLocations,
 dashboardMergedData: state.dashboardMergedData,
 userInfo: state.userInfo,
 notifications: state.notifications
});

export default 
 connect(
   mapStateToProps
 )(
   withStyles(styles, { withTheme: true })(MobileDrawer)
 );
