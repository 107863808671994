import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styles from '../../../theme/styles';
import ChatForm from "../../../forms/chat/form";
import * as chatActions from "../../../actions/chat";
import * as notificationActions from "../../../actions/notification";
import * as formNames from "../../../constants/forms";

import objectPath from 'object-path';
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import { withStyles } from '@material-ui/core/styles';
import SnackBarUtil from '../../../utils/SnackBarUtil';
import ComponentLoading from "../../../components/ComponentLoading";
import { change } from 'redux-form';
import TranslatorUtil from "../../../utils/TranslatorUtil";
import UrlBuilderUtil from "../../../utils/projectBased/UrlBuilderUtil";
import { Button, ListItem, List, ListItemIcon, ListItemText, ListItemAvatar, Divider, Box, IconButton, Hidden } from "@material-ui/core";
import UserAvatar from "../../../components/user/UserAvatar";
import UsersUtil from "../../../utils/projectBased/UsersUtil";
import classnames from 'classnames';
import ChatMessagesList from "../../../components/chat/ChatMessagesList";
import { Alert } from '@material-ui/lab';
import UiHelperUtil from "../../../utils/UiHelperUtil";
import ArrayUtil from "../../../utils/ArrayUtil";
import MessageItem from "../../../components/messages/MessageItem";
import ClearIcon from '@material-ui/icons/Clear';
import SmallDialog from "../../../components/dialogs/SmallDialog";
import AccessUtil from "../../../utils/projectBased/AccessUtil";
import EmailUtil from "../../../utils/projectBased/EmailUtil";
import CompanyUtil from "../../../utils/projectBased/CompanyUtil";
import ContactsIcon from '@material-ui/icons/Contacts';
import FullScreenDialog from "../../../components/FullScreenDialog";

const entityFormName = formNames.CHAT_CONVERSATION_FORM_NAME;


class Chat extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      selectedChatConversation: null,
      aboutMessage: null,
      messageDialogPreview: null,
      showModalContacts: false
    }
  }

  getMessageToUserId = () => {
    const { match, profiles, userInfo } = this.props;
    var userId = objectPath.get(match,'params.userToId',null);
    return profiles.find(x => x.uid === userId) 
    && userId 
    && objectPath.get(userInfo, "firebase.uid") !== userId //not current user
    ? userId : null;
  }

  getConversationAnotherUserId = (conversation) => {
    const { userInfo } = this.props;
    var currentUserId = objectPath.get(userInfo, "firebase.uid");
    var userIds = objectPath.get(conversation, 'userIds',[]);
    return userIds.find(x => x !== currentUserId);
  }


  componentDidMount(){
   
    this.setState({
      loading: true
    });
    Promise.all([
      this.listenChatConversations(),
      this.getAboutMessage()
    ]).then(() => {
      Promise.all([
      ]).then(() => {
        this.setState({
          loading: false
        });
      });
      
    }
    );
  }

  componentWillReceiveProps(nextProps){
    this.redirectToLatest(nextProps);
    this.mountCurrentChatConversation(nextProps);
  }

  getAboutMessage = () => {
    const { actions,match } = this.props;
    var projectId = objectPath.get(match, 'params.projectId', null);
    var discussionId = objectPath.get(match, 'params.discussionId', null);
    var messageId = objectPath.get(match, 'params.messageId', null);
    if(projectId && discussionId && messageId){
      return actions.getDiscussionMessage(projectId , discussionId , messageId).then((response) => {
        this.setState({
          aboutMessage: objectPath.get(response,'data',null)
        });
      });
    }
    return Promise.resolve();
  }


  redirectToLatest = (nextProps) => {
    const { chatConversations, match, history, userInfo } = nextProps || this.props;
    var userToId = objectPath.get(match, 'params.userToId', null);
    var currentUserId = objectPath.get(userInfo, "firebase.uid");
    if(currentUserId && userToId === null && ArrayUtil.isNonEmptyArray(chatConversations)){
      var latest = ArrayUtil.sortByDateUpdated(chatConversations)[0];
      if(latest && ArrayUtil.isNonEmptyArray(latest.userIds)){
        history.push(UrlBuilderUtil.getChat(latest.userIds.find(x => x !== currentUserId)));
      }
    }
  }

  mountCurrentChatConversation = (nextProps) => {
    const { userInfo, chatConversations, match, actions } = nextProps || this.props;
    const { selectedChatConversation } = this.state;
      if(chatConversations && chatConversations.length){
        var currentUserId = objectPath.get(userInfo, "firebase.uid");
        var userToId = objectPath.get(match, 'params.userToId');
        
        
        var newSelectedChatConversation = chatConversations.find(
          x => {
            var userIds = objectPath.get(x, 'userIds',[]);
          return userIds.length && userIds.indexOf(currentUserId) > -1 && userIds.indexOf(userToId) > -1
          } 
        );

        //console.log('mountCurrentChatConversation', {userInfo, chatConversations, match, currentUserId, userToId, newSelectedChatConversation})

        if(objectPath.get(selectedChatConversation,'id',null) !== objectPath.get(newSelectedChatConversation,'id',null)){

          this.setState({
            selectedChatConversation : newSelectedChatConversation
          });
          if(newSelectedChatConversation){
            //var messageListener = 
            actions.listenChatConversationMessages(newSelectedChatConversation.id);
          }
        }
        
      }
    
    
    
  }


  componentWillUnmount(){
    //const { actions } = this.props;
    //maybe remove listening
    //actions.flushDiscussionMessages()
  }


  listenChatConversations = () => {
    const { actions, userInfo } = this.props;
    if(userInfo){
      actions.listenChatConversations(objectPath.get(userInfo, "firebase.uid"));
    }
  }



  handleResponse = (response, values) => {
    const { enqueueSnackbar } = this.props;
    const { aboutMessage } = this.state;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      this.addMessageNotification(values,this.getMessageToUserId(),aboutMessage);
      if(aboutMessage){
        this.cancelAboutMessage();
      }
    }
  }

  addMessageNotification = (message,toUserId,aboutMessage) => {
    const { actions, userInfo } = this.props;
    const { selectedChatConversation } = this.state;
    

    return actions.putNotification(
      objectPath.get(selectedChatConversation,'id',toUserId),
      EmailUtil.getChatNotification(
        CompanyUtil.getId(),
        UsersUtil.getCurrentUserUid(),
        UsersUtil.getUserEmailFromStoreById(toUserId),
        message,
        UsersUtil.getUserDisplayNameByUserInfo(userInfo),
        aboutMessage
        )
      );
  }

  createConversation = () => {
    const { actions, userInfo } = this.props;
    return actions.postChatConversation({
      userIds: [objectPath.get(userInfo, "firebase.uid"), this.getMessageToUserId()]
    })
  }


  handleSubmit = values => {
    const { actions, userInfo, match } = this.props;
    const { selectedChatConversation, aboutMessage } = this.state;
    
    var projectId = objectPath.get(match, 'params.projectId', null);
    var discussionId = objectPath.get(match, 'params.discussionId', null);
    var messageId = objectPath.get(match, 'params.messageId', null);

    values.byUserId = objectPath.get(userInfo, "firebase.uid");
    if(selectedChatConversation){
      //put about message
      if(aboutMessage){
        values.projectId = projectId;
        values.discussionId = discussionId;
        values.messageId = messageId;
      }
      return actions.postChatConversationMessage(selectedChatConversation.id,values).then((response)=>this.handleResponse(response, values))
    }
    
  };


  cancelAboutMessage = () => {
    const { history } = this.props;
    this.setState({ aboutMessage: null });
        history.push(UrlBuilderUtil.getChat(this.getMessageToUserId()));
  }

  prependWithPerson = (conversation, currentUserId) => {
    return (currentUserId === objectPath.get(conversation,'lastMessageByUserId') ? TranslatorUtil.t('You')+': ': '');
  }

  renderConverations = () => {
    const { chatConversations, userInfo, history } = this.props;
    var currentUserId = objectPath.get(userInfo, "firebase.uid");
    return <List disablePadding={true}> {chatConversations && chatConversations.length ? 
      ArrayUtil.sortByDateUpdated(chatConversations).map((conversation, index) => {
        
        var anotherUserId = this.getConversationAnotherUserId(conversation);
        //console.log('conversation',conversation, anotherUserId);
        return currentUserId !== anotherUserId ? <ListItem divider={true} className={anotherUserId === this.getMessageToUserId() ? "active":""} key={index} onClick={() => {
          this.setState({showModalContacts:false});
          history.push(UrlBuilderUtil.getChat(anotherUserId));
        }}>
          <ListItemIcon>
            <UserAvatar userId={anotherUserId} />
          </ListItemIcon>
          <ListItemText primary={UsersUtil.getUserDisplayNameById(anotherUserId)} secondary={conversation.lastMessage ? <small>{UiHelperUtil.truncateIfNeeded(this.prependWithPerson(conversation,currentUserId) + conversation.lastMessage, 20)}</small> : null} />
      </ListItem>: null})
    : <ListItem><Alert severity="warning">{TranslatorUtil.t('No conversation started yet')}</Alert></ListItem>}</List>
  }

  

  getMessagePreview = () => {
    const { aboutMessage } = this.state;
    
    return aboutMessage ? <Box className="narrowMaxMediumWidthPart"><MessageItem message={aboutMessage} disableAvatarActions={true} disableReactions={true} /></Box> : null;
  }


  currentConversationUserPreview = () =>{
    return <div>
    <List className={"narrowMaxMediumWidthPart"} dense={true} disablePadding={true}>
     
     
       <ListItem alignItems="flex-start" dense={true} disableGutters={true}>
         
         <ListItemAvatar>
           <UserAvatar userId={this.getMessageToUserId()} menuItems={{withDialog: AccessUtil.isAtLeastObserver()}} /> 
         </ListItemAvatar>
         <ListItemText 
         primary={TranslatorUtil.t('Conversation with')}
         secondary={UsersUtil.getUserDisplayNameById(this.getMessageToUserId())}>
         </ListItemText>
       
     </ListItem>  
     </List>
     <Hidden smDown>
     <Divider />
     </Hidden>
     <Hidden mdUp>
     <IconButton color="primary" className="contactSelector" onClick={() => this.setState({showModalContacts:true})}>
           <ContactsIcon />
           </IconButton>
           </Hidden>
     
    </div>
  }

  showDialogMessagePreview = (projectId,discussionId,messageId) => {
    const { actions } = this.props;
    if(projectId && discussionId && messageId){
      return actions.getDiscussionMessage(projectId , discussionId , messageId).then((response) => {
        this.setState({
          messageDialogPreview: objectPath.get(response,'data',null)
        });
      });
    }
    return Promise.resolve();
  }

  cancelDialogMessagePreview = () => {
    this.setState({
      messageDialogPreview: null
    });
  }


  render() {
    const { classes, selectedChatConversationMessages, chatConversations, userInfo } = this.props;
    const { loading, selectedChatConversation, aboutMessage, messageDialogPreview,showModalContacts } = this.state;


    return (
      <div>
        <Hidden smDown>
        <div className={classnames(classes.conversationsWrap)}>
        {
          this.renderConverations()
        }
        </div>
        </Hidden>
        <div className={classnames(classes.conversationWrap,"conversationInnerWrap")}>
          <div className={classes.conversationInnerWrap}>
          
          {this.getMessageToUserId() ? ( loading ? <ComponentLoading /> : selectedChatConversation ? 
      <div>
        <div className={classnames(classes.chatMessagesWrap,"chatMessagesWrap")}>
        <Hidden smDown>
            {this.currentConversationUserPreview()}
          </Hidden>
         
         <ChatMessagesList items={selectedChatConversationMessages} currentUserId={objectPath.get(userInfo, "firebase.uid")} showDialogMessagePreview={this.showDialogMessagePreview} />
        </div>
        <div  className={classnames(classes.chatFormWrap,"chatFormWrap")}>
          {aboutMessage ? <Box className="narrowMaxMediumWidthPart aboutMessageWrapper">
            {this.getMessagePreview()}
          <IconButton aria-label="delete" onClick={this.cancelAboutMessage} size="small" className="cancelBtn">
          <ClearIcon fontSize="inherit" color="error" />
        </IconButton></Box> : null
        }
          <ChatForm 
            proceedSubmit={this.handleSubmit} 
            initialValues={null}
          />
          <Hidden mdUp>
          {this.currentConversationUserPreview()}
          </Hidden>
        </div>
      </div>
       : <div><Button color="primary" onClick={this.createConversation}>
              <UserAvatar userId={this.getMessageToUserId()} /> {TranslatorUtil.t('Start conversation with')} {UsersUtil.getUserDisplayNameById(this.getMessageToUserId())}
            </Button>
            {this.getMessagePreview()}
            </div> ) : chatConversations && chatConversations.length ? <Alert severity="info">{TranslatorUtil.t('Select conversation please')}</Alert>: null}
          </div>
      
        </div>
        {
          messageDialogPreview ? <SmallDialog
          title={TranslatorUtil.t("Message preview")}
          handleClose={this.cancelDialogMessagePreview}
          >
            <MessageItem message={messageDialogPreview} currentUserId={objectPath.get(userInfo, "firebase.uid")} disableAvatarActions={true} disableReactions={true} />
          </SmallDialog>
           : null
        }

        {
          showModalContacts ? <FullScreenDialog
          title={TranslatorUtil.t("Select conversation")}
          closeDialog={() => this.setState({showModalContacts:false})}
          >
            {
          this.renderConverations()
        }
          </FullScreenDialog>
           : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
  chatConversations: state.chatConversations,
  selectedChatConversationMessages: state.selectedChatConversationMessages,
  profiles: state.profiles,
});

const mapDispatchToProps = dispatch =>
({
  changeFieldValue: function(field, value) {
    dispatch(change(entityFormName, field, value))
  },
  actions: bindActionCreators(
    {
      ...chatActions,
      ...notificationActions
    },
    dispatch
  )
});


export default withRouter(withSnackbar(withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Chat)
)));