import React from 'react'
import AppCard from '../../components/AppCard';
import { Typography, Button } from '@material-ui/core';
import queryString from 'query-string'
import objectPath from 'object-path'
import TranslatorUtil from '../../utils/TranslatorUtil';

const UnauthorisedAccessPage = ({ location }) => {
  let searchParams = queryString.parse(location.search);
  let requestedPath = objectPath.get(searchParams, 'request', null);
  return (
    <div>
      <AppCard type="error">
          <Typography variant="h1">401 - {TranslatorUtil.t("Unauthorised")}</Typography>
          <br />
          <Typography variant="body1">
          {TranslatorUtil.t("Unauthorised attempt to access")} { requestedPath ? <code><br />{requestedPath}</code> : null }
          </Typography>
          <br />
          <br />
          <Button href="/" variant="contained" color="default">{TranslatorUtil.t("Home page")}</Button>
      </AppCard>
    </div>
  )
} 


export default UnauthorisedAccessPage
