import DateUtil from "../DateUtil";

export default class NotificationUtil {

    static addMetaDataToNew(data){
      data.createdTime = DateUtil.unixTime();
      data.read = false;
      return data;
      }

}
