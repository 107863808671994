import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import firestoreApi from '../firebase.js';
import FirestoreCollectionUtil from "../utils/FirestoreCollectionUtil";
import * as collections from '../constants/firebaseCollections';
import CompanyUtil from "../utils/projectBased/CompanyUtil";
import DateUtil from "../utils/DateUtil";

const collectionName = collections.DISCUSSIONS;


export function getDiscussions(projectId) {
  return function(dispatch) {
    console.log('getDiscussions of project ',projectId);
    return firestoreApi.collection(
      CompanyUtil.getCollectionPathWithCompanyPrefix(collections.PROJECTS, [projectId,collectionName])).get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_DISCUSSIONS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_DISCUSSIONS))
      );
  };
}

export function flushDiscussionMessages() {
  return function(dispatch) {
    return dispatch(ajaxCalls.ajaxCallSuccess([], types.GET_DISCUSSION_MESSAGES))
  };
}

export function listenDiscussionMessages(projectId,discussionId) {
  return function(dispatch) {
    console.log('listenDiscussionMessages called');
    return firestoreApi.collection(
      CompanyUtil.getCollectionPathWithCompanyPrefix(collections.PROJECTS, [projectId,collectionName,discussionId,collections.MESSAGES])
    )
    .onSnapshot(response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_DISCUSSION_MESSAGES)))
      
    // .then(
    //     response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.LISTEN_DISCUSSION_MESSAGES)),
    //     error => dispatch(ajaxCalls.ajaxCallError(error, types.LISTEN_DISCUSSION_MESSAGES))
    //   );
  };
}

export function getDiscussionMessages(projectId,discussionId) {
  return function(dispatch) {
    console.log('getDiscussionMessages',projectId,discussionId);
    return firestoreApi.collection(
      CompanyUtil.getCollectionPathWithCompanyPrefix(collections.PROJECTS, [projectId,collectionName,discussionId,collections.MESSAGES])
      ).get().then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_DISCUSSION_MESSAGES)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_DISCUSSION_MESSAGES))
      );
  };
}

export function getDiscussion(projectId,discussionId) {
  return function(dispatch) {
    console.log('getDiscussion');
    return firestoreApi.collection(
      CompanyUtil.getCollectionPathWithCompanyPrefix(collections.PROJECTS, [projectId,collectionName])
      ).doc(discussionId).get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionItemData(response), types.GET_DISCUSSION)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_DISCUSSION))
      );
  };
}

export function postDiscussion(projectId,data) {
  return function(dispatch) {
    console.log('postDiscussion');
    data.createdTime = DateUtil.timestamp();
    data.updatedTime = DateUtil.timestamp();
    return firestoreApi.collection(
      CompanyUtil.getCollectionPathWithCompanyPrefix(collections.PROJECTS, [projectId,collectionName])
    ).add(data)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_DISCUSSION)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_DISCUSSION))
      );
  };
}

export function postDiscussionMessage(projectId,discussionId,data) {
  return function(dispatch) {
    console.log('postMessage');
    data.createdTime = DateUtil.timestamp();
    return firestoreApi.collection(
      CompanyUtil.getCollectionPathWithCompanyPrefix(collections.PROJECTS, [projectId,collectionName,discussionId,collections.MESSAGES])
    ).add(data)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_DISCUSSION_MESSAGE)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_DISCUSSION_MESSAGE))
      );
  };
}

export function putDiscussion(projectId,data) {
  return function(dispatch) {
    console.log('putDiscussion');
    data.updatedTime = DateUtil.timestamp();
    return firestoreApi.collection(
      CompanyUtil.getCollectionPathWithCompanyPrefix(collections.PROJECTS, [projectId,collectionName])
    ).doc(data.id).set(FirestoreCollectionUtil.removeMetaData(data))
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PUT_DISCUSSION)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.PUT_DISCUSSION))
      );
  };
}

export function deleteDiscussion(projectId,id) {
  return function(dispatch) {
    console.log('deleteDiscussion');
    return firestoreApi.collection(
      CompanyUtil.getCollectionPathWithCompanyPrefix(collections.PROJECTS, [projectId,collectionName])
    ).doc(id).delete()
    .then(
      response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.DELETE_DISCUSSION)),
      error => dispatch(ajaxCalls.ajaxCallError(error, types.DELETE_DISCUSSION))
    );
    };
}

export function flushDiscussionLikes() {
  return function(dispatch) {
    return dispatch(ajaxCalls.ajaxCallSuccess([], types.GET_DISCUSSION_LIKES))
  };
}

export function listenDiscussionLikes(projectId,discussionId) {
  return function(dispatch) {
    console.log('listenDiscussionMessageLikes called');
    return firestoreApi.collection(
      CompanyUtil.getCollectionPathWithCompanyPrefix(collections.PROJECTS, [projectId,collectionName,discussionId,collections.LIKES])
    )
    .onSnapshot(response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_DISCUSSION_LIKES)))
  };
}

export function getDiscussionLikes(projectId,discussionId) {
  return function(dispatch) {
    console.log('getDiscussionLikes',projectId,discussionId);
    return firestoreApi.collection(
      CompanyUtil.getCollectionPathWithCompanyPrefix(collections.PROJECTS, [projectId,collectionName,discussionId,collections.LIKES])
      ).get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_DISCUSSION_LIKES)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_DISCUSSION_LIKES))
      );
  };
}

export function putDiscussionMessageLikes(projectId,discussionId,data) {
  return function(dispatch) {
    console.log('putDiscussionMessageLikes');
    data.updatedTime = DateUtil.timestamp();
    return firestoreApi.collection(
      CompanyUtil.getCollectionPathWithCompanyPrefix(collections.PROJECTS, [projectId,collectionName,discussionId,collections.LIKES])
    )
    .doc(data.id).set(FirestoreCollectionUtil.removeMetaData(data))
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PUT_DISCUSSION_MESSAGE_LIKES)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.PUT_DISCUSSION_MESSAGE_LIKES))
      );
  };
}

export function lockDiscussion(projectId,id) {
  return function(dispatch) {
    console.log('lockDiscussion');
    return firestoreApi.collection(
      CompanyUtil.getCollectionPathWithCompanyPrefix(collections.PROJECTS, [projectId,collectionName])
    )
    .doc(id)
    .update({
      locked : true,
      lockDate : DateUtil.backendDateFormat(),
      lockTime : DateUtil.backendTimeFormat(),
      updatedTime : DateUtil.timestamp(),
    })
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.LOCK_DISCUSSION)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.LOCK_DISCUSSION))
      );
  };
}

export function unlockDiscussion(projectId, id) {
  return function(dispatch) {
    console.log('unlockDiscussion');
    return firestoreApi.collection(
      CompanyUtil.getCollectionPathWithCompanyPrefix(collections.PROJECTS, [projectId,collectionName])
    )
    .doc(id)
    .update({locked:false,lockDate:null,lockTime:null,updatedTime : DateUtil.timestamp()})
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.UNLOCK_DISCUSSION)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.UNLOCK_DISCUSSION))
      );
  };
}
