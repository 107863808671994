const messages = {
    "langCode": "cs",
    "Dashboard": "Nástěnka",
    "Password": "Heslo",
    "Name": "Jméno",
    "Surname": "Příjmení",
    "Admin": "Správce",
    "Email": "E-mail",
    "Login": "Přihlásit se",
    "Sign in": "Vytvořit účet",
    "Logout": "Odhlásit se",
    "Items": "Položky",
    "Password recovery link has been sent to your registered e-mail address.": "Odkaz pro obnovení hesla byl odeslán na váš zaregistrovaný e-mail.",
    "Email with password recovery link sent": "E-mail s odkazem pro obnovení hesla odeslán.",
    "You are logged in": "Jste přihlášen/a.",
    "No conversation started yet": "Zatím nebyla zahájena žádná konverzace",
    "No conversation started yet.": "Zatím nebyla zahájena žádná konverzace.",
    "Reset password": "Obnovit heslo",
    "Confirm password": "Zopakujte heslo.",
    "Passwords do not match": "Hesla se neshodují.",
    "Please wait": "Načítání",
    "Show more": "Zobrazit více",
    "SimpleItem": "Položka",
    "Simple Item": "Položka",
    "Simple Items": "Položky",
    "Please confirm you wish to remove ": "Potvrďte prosím odstranění",
    "Are you sure?": "Jste si jistí?",
    "Cancel": "Zrušit",
    "No notifications found": "Nemáte žádné notifikace.",
    "New": "Nový",
    "New Discussion": "Nová diskuse",
    "Update Discussion": "Aktualizovat diskusi",
    "Update": "Aktualizovat",
    "Save": "Uložit",
    "Delete": "Odstranit",
    "Edit": "Upravit",
    "Projects": "Projekty",
    "Project": "Projekt",
    "Profile": "Profil",
    "Back": "Zpět",
    "back": "zpět",
    "Description": "Popis",
    "Profile description": "Kdo jsem aneb Jak byste se představili novému známému ve 3 větách?",
    "User profile": "Profil uživatele",
    "Open chat": "Napsat soukromou zprávu",
    "Comment": "Komentář",
    "Message": "Zpráva",
    "Created": "Vytvořeno",
    "No results": "Nic nenalezeno",
    "Select conversation please": "Vyberte prosím konverzaci.",
    "male": "muž",
    "female": "žena",
    "Required": "Povinné",
    "Uploading": "Nahrávám",
    "uploaded": "nahrán",
    "locked": "uzamčen",
    "unlocked": "odemčen",
    "Locked on project level!": "Zamčeno na úrovni projektu!",
    "Lock": "Uzamknout",
    "Unlock": "Odemknout",
    "discussion for all respondents manually": "diskusi pro všechny respondenty manuálně.",
    "project and its discussions for all respondents manually": "projekt a jeho diskuse pro všechny respondenty ručně.",
    "manually": "manuálně",
    "Error": "Chyba",
    "Unfortunately something went wrong and invitation has expired or is not valid anymore": "Bohužel se něco pokazilo a pozvání vypršelo nebo již není platné",
    "Unfortunately something went wrong and the invitation has expired or is not valid anymore.": "Bohužel se něco pokazilo a pozvání vypršelo nebo již není platné.",
    "Project unlocked": "Projekt odemknut",
    "Project locked": "Projekt zamknut",
    "Age": "Věk",
    "Gender": "Pohlaví",
    "Phone": "Telefon",
    "Language": "Jazyk",
    "Turn off email notifications": "Vypněte e-mailová oznámení",
    "Not selected": "Nevybráno",
    "Discussions": "Diskuze",
    "Select project": "Vyberte projekt",
    "Users": "Uživatelé",
    "Add new item": "Přidat novou položku",
    "Confirm": "Potvrdit",
    "Start discussion": "Zahájit diskusi",
    "Add": "Přidat",
    "Select": "Vybrat",
    "By accepting this invitation you agree with terms and conditions above": "Přijetím této pozvánky souhlasíte s výše uvedenými podmínkami",
    "By accepting this invitation you agree with terms and conditions above.": "Přijetím této pozvánky souhlasíte s výše uvedenými podmínkami.",
    "Accept invitation": "Přijmout pozvánku",
    "Login first please": "Nejprve se, prosím, přihlaste.",
    "View ": "Zobrazit ",
    "company": "firma",
    "Company": "Firma",
    "Company admins": "Administrátorské účty firmy",
    "Chat": "Zprávy",
    "Reply": "Reagovat",
    "Please submit a message to see opinions of others": "Chcete-li zobrazit názory ostatních, odešlete alespoň jednu zprávu.",
    "Please submit a message to see opinions of others.": "Chcete-li zobrazit názory ostatních, odešlete alespoň jednu zprávu.",
    "Mark as read": "Označit jako přečtené",
    "Preview": "Náhled",
    "Notify users": "Informujte uživatele",
    "All project users": "Všechny uživatele projektu",
    "You": "Vy",
    "Terms and conditions": "Pravidla a podmínky",
    "Chat about this message": "Diskutovat o této zprávě",
    "Comment added": "Komentář přidán.",
    "User tags updated": "Štítky uživatele aktualizovány.",
    "User not found": "Uživatel nenalezen.",
    "close": "zavřít",
    "Welcome back": "Vítejte zpět",
    "Please continue below if you forgot your password or do not have account": "Pokračujte níže, pokud jste zapomněli heslo nebo nemáte účet.",
    "Please continue below if you forgot your password or do not have an account yet.": "Pokračujte níže, pokud jste zapomněli heslo nebo nemáte účet.",
    "If you think you know the password go back to login below": "Pokud si myslíte, že znáte heslo, vraťte se zpět a přihlaste se",
    "If you think you know the password go back to the login below.": "Pokud si myslíte, že znáte heslo, vraťte se zpět a přihlaste se.",
    "If you already have an account go straight to login below": "Pokud již máte účet, přejděte rovnou k přihlášení níže.",
    "If you already have an account go straight to the login below.": "Pokud již máte účet, přejděte rovnou k přihlášení níže.",
    "Conversation with": "Konverzace s",
    "Start conversation with": "Zahájit konverzaci s",
    "Message preview": "Náhled zprávy",
    "Youtube Embed Link": "Odkaz pro vložení na YouTube",
    "Color": "Barva",
    "Images": "Obrázky",
    "max 8MB per file": "maximálně 8 MB na soubor",
    "Welcome page content": "Obsah uvítací stránky",
    "Terms & Conditions": "Všeobecné podmínky",
    "Lock date": "Datum uzamknutí",
    "Lock time": "Čas uzamknutí",
    "Archived projects": "Archivované projekty",
    "respondents": "respondenti",
    "observers": "pozorovatelé",
    "moderators": "moderátoři",
    "Role to notify": "Informovat roli",
    "Subject": "Předmět",
    "Notify users of ": "Informovat uživatele –",
    "Manage access for ": "Spravovat přístup pro ",
    "project": "projekt",
    "Invite users via link (users not listed within any role will be created as respondents)": "Pozvat uživatele prostřednictvím odkazu (uživatelé, kteří nejsou uvedeni v žádné roli, budou vytvořeni jako respondenti)",
    "Copy link": "Kopírovat odkaz",
    "Link copied": "Odkaz zkopírován",
    "name: {:projectName} description: {:projectDescription}": "Název: {:projectName} popis: {:projectDescription}",
    "name: {:projectName}<br />description: {:projectDescription}": "Název: {:projectName}<br />Popis: {:projectDescription}",
    "Project invitation {:projectName}": "Pozvánka na projekt {:projectName}",
    "name: {:projectName} email: {:email} role: {:roleType}": "název: {:projectName} email: {:email} role: {:roleType}",
    "name: {:projectName}<br />invitation accepted by: {:email}<br />role: {:roleType}": "Název: {:projectName}<br />Pozvání přijato uživatelem: {:email}<br />role: {:roleType}",
    "Invitation accepted - {:projectName}": "Pozvánka přijata – {:projectName}",
    "Invitation accepted – {:projectName}": "Pozvánka přijata – {:projectName}",
    "Discussion is closed": "Diskuse je uzavřena.",
    "Profile updated by respondent": "Profil aktualizován respondentem",
    "Profile updated by user {:profileEmail}": "Profil aktualizován uživatelem {:profileEmail}",
    "Page not found": "Požadovaná stránka neexistuje.",
    "Internal Server Error": "Chyba serveru",
    "Sorry, something went wrong Please try again later": "Promiňte, něco se pokazilo. Prosím, zkuste to znovu později.",
    "Sorry, something went wrong. Please try again later.": "Promiňte, něco se pokazilo. Prosím, zkuste to znovu později.",
    "Home page": "Domovská stránka",
    "Unauthorised attempt to access": "Neoprávněný pokus o přístup",
    "Unauthorised": "Neoprávněný přístup",
    "as anonym": "jako anonym",
    "New message from user {:fromName} - {:message}": "Nová zpráva od {:fromName} – {:message}",
    "New message from user {:fromName} – {:message}": "Nová zpráva od {:fromName} – {:message}",
    "New message": "Nová zpráva",
    "See more": "Zobrazit více",
    "Discussion": "Diskuze",
    "Select conversation": "Vyberte konverzaci",
    "New reaction {:discussionName} has been added": "Přibyla nová reakce u diskuze {:discussionName}",
    "New reaction {:discussionName} has been added.": "Přibyla nová reakce u diskuze {:discussionName}.",
    "Your message {:messageText} in {:discussionName} has new reaction {:replyText}": "Vaše zpráva {:messageText} v diskuzi {:discussionName} má novou reakci {:replyText}",
    "Discussion {:discussionName} has been added": "Byla přidána diskuze {:discussionName}",
    "Discussion {:discussionName} has been added.": "Byla přidána diskuze {:discussionName}.",
    "Profile registration : {:email}": "Registrace profilu : {:email}",
    "Profile registration": "Registrace profilu",
    "Discussion {:discussionName} has been added to {:projectName}": "Nová diskuze {:discussionName} byla přidána k projektu {:projectName}",
    "Discussion {:discussionName} has been added to {:projectName}.": "Nová diskuze {:discussionName} byla přidána k projektu {:projectName}.",
    "Discussion {:discussionName} has been added to {:projectName} {:discussionIntroduction}": "Nová diskuze {:discussionName} byla přidána k projektu {:projectName}.<br/> {:discussionIntroduction}. <br/>",
    "Generate exports": "Generovat export",
    "Download messages export": "Export zpráv ke stažení",
    "Download users export": "Export uživatelů ke stažení",
    "Tags": "Štítky",
    "Reply to message": "Reagovat na zprávu",
};

export default messages;