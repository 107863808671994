import * as types from '../constants/actionTypes';


export function getMessages(state = [], action) {
    if (action.type === types.GET_DISCUSSION_MESSAGES) {
        console.log(types.GET_DISCUSSION_MESSAGES+' set in store');
        return action.data;
    }
    return state;
}


export function getDiscussionLikes(state = [], action) {
    if (action.type === types.GET_DISCUSSION_LIKES) {
        console.log(types.GET_DISCUSSION_LIKES+' set in store');
        return action.data;
    }
    return state;
}



