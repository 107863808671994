import React from "react";
import { Field, reduxForm } from "redux-form";
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import classnames from 'classnames';
import * as formNames from "../../constants/forms";
import * as constants from '../../constants/constants';
import {
  SelectWithCreateAndSuggestions
} from "../../components/inputs";


import validate from "./validate";
import Fab from "@material-ui/core/Fab";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FormGroup from "@material-ui/core/FormGroup";
import { Grid, Tooltip, } from "@material-ui/core";
import TranslatorUtil from "../../utils/TranslatorUtil";

const UserTagsForm = props => {
  const {
    userTags,
    handleSubmit,
    //pristine,
    submitting,
    classes,
    proceedSubmit,
    validateNewOption
  } = props;
  
  const disableSubmit = submitting;
  const submitButton = <Fab color="secondary" type="submit" size="large" disabled={disableSubmit}><ArrowForwardIcon /></Fab>;

  return (
    <div>
        <form onSubmit={handleSubmit(values => proceedSubmit(values)
          //.then(() => reset())
          )} className={classes.customForm}>
           
          <div className="maxWidthPart">
          <Grid container>
            
          <Grid item className="gridItem" xs={10} md={10}>
          <FormGroup>
                <Field name="tags" 
                component={SelectWithCreateAndSuggestions} 
                isSearchable={true} 
                isMulti={true} 
                options={userTags} 
                validateNewOption={validateNewOption}
                label={TranslatorUtil.t("Tags")}  />
              </FormGroup>

              </Grid>
            
            
            <Grid item className={classnames("gridItem",classes.textRightStrong)}  xs={2} md={2}>
              { disableSubmit ? submitButton : <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("OK")}>{submitButton}</Tooltip> }
            </Grid>
          </Grid>
          </div>
        </form>
    </div>
  );
};


export default withStyles(styles, { withTheme: true })(reduxForm({
  form: formNames.USER_TAGS_FORM_NAME, // a unique identifier for this form
  validate,
  //asyncValidate
})(UserTagsForm),formNames.USER_TAGS_FORM_NAME);
