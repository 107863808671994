import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PageHeading from "../../../components/PageHeading";
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../theme/styles';
import * as discussionActions from "../../../actions/discussion";
import * as projectActions from "../../../actions/project";
import * as notificationActions from "../../../actions/notification";
import objectPath from 'object-path';
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import ReactSelect from 'react-select';
import HeadingBackBtn from "../../../components/buttons/HeadingBackBtn";
import ComponentLoading from "../../../components/ComponentLoading";
import FormatterUtil from "../../../utils/FormatterUtil";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import UrlBuilderUtil from "../../../utils/projectBased/UrlBuilderUtil";

import { Divider, List, ListItem, ListItemIcon, ListItemText, Paper } from "@material-ui/core";
import AccessUtil from "../../../utils/projectBased/AccessUtil";
import CompanyUtil from "../../../utils/projectBased/CompanyUtil";

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { CSVLink } from "react-csv";
import DateUtil from "../../../utils/DateUtil";


import { Tooltip, IconButton } from "@material-ui/core";
import ArrayUtil from "../../../utils/ArrayUtil";
import UsersUtil from "../../../utils/projectBased/UsersUtil";
import * as constants from '../../../constants/constants';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import age from "../../../constants/age";
import gender from "../../../constants/gender";



class ExportDiscussion extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      project: null,
      discussion: null,
      dataLoading: true,
      selectedTags: [],
    }
  }

  componentDidMount() {
    const { match, actions } = this.props;
    var discussionId = UrlBuilderUtil.getRequestedParam(match, 'discussionId');
    var projectId = UrlBuilderUtil.getRequestedParam(match, 'projectId');
    Promise.all([
      this.mountProject(projectId),
      this.mountDiscussion(projectId, discussionId),
      actions.getDiscussionMessages(projectId, discussionId),
      actions.getDiscussionLikes(projectId, discussionId)
      
    ]).then(() => {

      return this.setState({
        dataLoading: false
      })
    }
    );
  }





  mountProject = (projectId) => {
    const { actions, selectedProject } = this.props;
    AccessUtil.isAtLeastModeratorForProject(projectId, true);
    if (!selectedProject || (selectedProject && selectedProject.id !== projectId)) {
      return actions.getProject(projectId);
    } else {
      return Promise.resolve();
    }
  }

  mountDiscussion = (projectId, discussionId) => {
    const { actions } = this.props;
    const { discussion } = this.state;
    const _this = this;
    if (projectId && discussionId) {
      if (!discussion || (discussion && discussion.id !== discussionId)) {
        AccessUtil.isAtLeastRespondentForProject(projectId, true);
        return actions.getDiscussion(projectId, discussionId).then(response => {
          return _this.setState({
            discussion: objectPath.get(response, "data"),
          })
        });
      } else {
        return Promise.resolve();
      }


    } else {
      return new Promise((resolve) => {

        _this.setState({
          discussion: null,
        }, resolve);
      });
    }
  }



  getFilteredUsers = () => {
    const { match, profiles, messages, discussionLikes } = this.props;
    const { selectedTags } = this.state;

    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");

    let respondentEmails = AccessUtil.getRespondentsAccessByProject(projectId, false);
    let projectUserEmails = respondentEmails.concat(
      AccessUtil.getModeratorsAccessByProject(projectId, false),
      CompanyUtil.getCurrentCompanyAdminEmails()
    );

    let users = profiles.filter(x => projectUserEmails.includes(x.email)).map(x => {
      var cloned = Object.assign({}, x);
      cloned.messagesCount = messages.filter(x => x.byUserId === cloned.uid).length;
      cloned.responsesCount = messages.filter(x => x.byUserId === cloned.uid && objectPath.has(x, 'replyToMessageId')).length;
      cloned.localized_gender = objectPath.has(cloned, 'gender') ? TranslatorUtil.t(cloned.gender) : '';
      cloned.isRespondentOnly = respondentEmails.includes(cloned.email) ? 1 : 0;
      cloned.userTags = UsersUtil.getUserTagsByUserId(cloned.uid);
      cloned.likesCount = discussionLikes.filter(x => x.likes.includes(cloned.uid)).length;
      cloned.dislikesCount = discussionLikes.filter(x => x.dislikes.includes(cloned.uid)).length;
      Object.assign(cloned, this.getUserTagsColumns(cloned));
      return cloned;
    });
    

    //apply filters
    if(selectedTags && selectedTags.length){
      users = users.filter(x => {
        var matchesNeeded = selectedTags.length;
        var matchesFound = 0;
        selectedTags.forEach(t => {
          var tagValue = t.value;
          if(tagValue.indexOf('__profile__') !== -1){
            if(tagValue.indexOf('__profile__age__') !== -1 && x.age === tagValue.replace('__profile__age__','')){
              matchesFound++;
            }
            if(tagValue.indexOf('__profile__gender__') !== -1 && x.gender === tagValue.replace('__profile__gender__','')){
              matchesFound++;
            }
          }
          else if(x.userTags.includes(tagValue)){
            matchesFound++;
          }
        });
        return matchesFound === matchesNeeded;
      })
    }

    return users;
  }

  getUserTagsColumns = (user) => {
    var userTags = {};

    CompanyUtil.getCompanyUserTags().forEach(tag => {
      userTags["tag_"+FormatterUtil.getSlugVersion(tag)] = user.userTags.includes(tag) ? 1 : 0;
    });
    return userTags;
  }

  getFilteredMessages = () => {
    const { messages, discussionLikes, match } = this.props;
    let users = this.getFilteredUsers();
    let userIds = users.map(x => x.uid);
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");
    var discussionId = UrlBuilderUtil.getRequestedParam(match, "discussionId");

    let output = ArrayUtil.sortByDateCreated(messages, false).filter(m => userIds.includes(m.byUserId)).map(m => {
      var cloned = Object.assign({}, m);
      var user = users.find(u => u.uid === m.byUserId);
      var messageUser = {};
      if(user){
        Object.keys(user).forEach(p => {
          messageUser["user_"+p] = objectPath.get(user,p,'');
        });
        Object.assign(messageUser, this.getUserTagsColumns(user));
      }

      cloned.directReactionsCount = messages.filter(x => objectPath.get(x, 'replyToMessageId', null) === m.id).length;
      cloned.likesCount = objectPath.get(discussionLikes.filter(x => objectPath.get(x, 'id', null) === m.id), 'likes', []).length;
      cloned.dislikesCount = objectPath.get(discussionLikes.filter(x => objectPath.get(x, 'id', null) === m.id), 'likes', []).length;
      cloned.link = UrlBuilderUtil.getRquestedHostUrl()+UrlBuilderUtil.getDiscussionConversation(projectId,discussionId,cloned.id);
      cloned.replyToLink = objectPath.get(cloned, 'replyToMessageId', null) ? UrlBuilderUtil.getRquestedHostUrl()+UrlBuilderUtil.getDiscussionConversation(projectId,discussionId,cloned.replyToMessageId) : '';

      

      return Object.assign(cloned, messageUser);
    });




    return output;

  }



  getExportData = (type) => {
    

    if(type === "users"){
      return this.getFilteredUsers();
    } 
    
    return this.getFilteredMessages();

  }

  
  getFilterTags = () => {
    var profileAge = age.map(x=>{
      if(x.value.indexOf("__profile__") === -1){
        x.value = "__profile__age__"+x.value;
        x.label = "("+TranslatorUtil.t("Age")+"*) "+x.label;
      }
      return x;
    });
    var profileGender = gender.map(x=>{
      if(x.value.indexOf("__profile__") === -1){
        x.value = "__profile__gender__"+x.value;
        x.label = "("+TranslatorUtil.t("Gender")+"*) "+x.label;
      }
      return x;
    });
    return CompanyUtil.getCompanyUserTags().map(x => ({
      label: x,
      value: x
    })).concat(
      profileAge,
      profileGender
    );
  }

  getExportHeaders = (type) => {
    var userPrepend = TranslatorUtil.t("user") + "-";
    var fixHeaders = type === "users" ? [
      { label: TranslatorUtil.t("Total messages count"), key: "messagesCount" },
      { label: TranslatorUtil.t("Responses count"), key: "responsesCount" },
      { label: TranslatorUtil.t("Likes count"), key: "likesCount" },
      { label: TranslatorUtil.t("Dislikes count"), key: "dislikesCount" },
      { label: TranslatorUtil.t("Is respondent"), key: "isRespondentOnly" },
      
      { label: TranslatorUtil.t("Display name"), key: "displayName" },
      { label: TranslatorUtil.t("Email"), key: "email" },
      { label: TranslatorUtil.t("Phone"), key: "phone" },
      { label: TranslatorUtil.t("Description"), key: "description" },
      
      { label: TranslatorUtil.t("Age"), key: "age" },
      { label: TranslatorUtil.t("Gender"), key: "localized_gender" },
      // { label: TranslatorUtil.t("Email notifications off"), key: "emailNotificationsOff" },
      // { label: TranslatorUtil.t("id"), key: "uid" },
    ] : [
      { label: TranslatorUtil.t("Message"), key: "message" },
      { label: TranslatorUtil.t("Direct responses count"), key: "directReactionsCount" },
      { label: TranslatorUtil.t("Likes count"), key: "likesCount" },
      { label: TranslatorUtil.t("Dislikes count"), key: "dislikesCount" },
      { label: TranslatorUtil.t("anonymous"), key: "anonymous" },
      { label: TranslatorUtil.t("created"), key: "createdTime" },
      //{ label: TranslatorUtil.t("reply to message id"), key: "replyToMessageId" },
      { label: TranslatorUtil.t("message link"), key: "link" },
      { label: TranslatorUtil.t("reply to message link"), key: "replyToLink" },

      { label: userPrepend+TranslatorUtil.t("Total messages count"), key: "user_messagesCount" },
      { label: userPrepend+TranslatorUtil.t("Responses count"), key: "user_responsesCount" },
      { label: userPrepend+TranslatorUtil.t("Likes count"), key: "user_likesCount" },
      { label: userPrepend+TranslatorUtil.t("Dislikes count"), key: "user_dislikesCount" },
      { label: userPrepend+TranslatorUtil.t("Is respondent"), key: "user_isRespondentOnly" },
      
      { label: userPrepend+TranslatorUtil.t("Display name"), key: "user_displayName" },
      { label: userPrepend+TranslatorUtil.t("Email"), key: "user_email" },
      { label: userPrepend+TranslatorUtil.t("Phone"), key: "user_phone" },
      { label: userPrepend+TranslatorUtil.t("Description"), key: "user_description" },
      { label: userPrepend+TranslatorUtil.t("Age"), key: "user_age" },
      { label: userPrepend+TranslatorUtil.t("Gender"), key: "user_localized_gender" },
      
      // { label: userPrepend+TranslatorUtil.t("Email notifications off"), key: "user_emailNotificationsOff" },
      // { label: userPrepend+TranslatorUtil.t("Tags"), key: "user_userTags" },
      // { label: userPrepend+TranslatorUtil.t("id"), key: "user_uid" },
      
    ];


    return fixHeaders.concat(CompanyUtil.getCompanyUserTags().map(x => ({ label: x, key: "tag_"+FormatterUtil.getSlugVersion(x) }))); 
  }

  getExportFileName = (type) => {
    const { selectedProject } = this.props;
    const { discussion } = this.state;
    return FormatterUtil.getSlugVersion(selectedProject?.name + "-" + discussion?.name + "-" + TranslatorUtil.t(type) + "-" + DateUtil.localizedTime() + ".csv");
  }

  onSelectionChange = (value) => {

    this.setState({ selectedTags: value });
  }


  render() {
    const { match, history } = this.props;
    const { dataLoading, discussion,selectedTags } = this.state;

    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");
    var discussionId = UrlBuilderUtil.getRequestedParam(match, "discussionId");

    const usersExport = this.getExportData("users");
    const messagesExport = this.getExportData("messages");

    return (
      <div>
        <PageHeading heading={TranslatorUtil.t("Export") + " - " + (discussion ? discussion.name : TranslatorUtil.t('Loading'))} actions={
          <div>
            <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Project")}>
              <IconButton aria-label={TranslatorUtil.t("Project")} onClick={() => history.push(UrlBuilderUtil.getProjectDashboard(projectId))}>
                <BusinessCenterIcon />
              </IconButton>
            </Tooltip>
            <HeadingBackBtn redirectToUrl={UrlBuilderUtil.getDiscussionConversation(projectId, discussionId)} />
          </div>
        } />

        { dataLoading ? <ComponentLoading /> : <React.Fragment>
          <div><br /><br /></div>
          <div className="narrowMaxMediumWidthPart">
          <Paper elevation={3} >
          <div className="selectSuggestion filter">
              <ReactSelect 
              value={selectedTags}
              isMulti 
              isClearable
              isSearchable
              placeholder={TranslatorUtil.t("Tags")}
              onChange={this.onSelectionChange}
              options={this.getFilterTags()} />
              </div>
            <List>
            <ListItem>
                <br />
              </ListItem>
              <ListItem>

                <ListItemText primary={TranslatorUtil.t("Download users export")+" ("+usersExport.length+")"} />
                <ListItemIcon>
                  <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Download users export")}>
                    <CSVLink data={usersExport}
                      headers={this.getExportHeaders("users")}
                      target="_blank"
                      filename={this.getExportFileName("users")}>
                      <IconButton><CloudDownloadIcon size="small" /></IconButton></CSVLink>
                  </Tooltip>
                </ListItemIcon>
              </ListItem>
              <ListItem>
              </ListItem>
              <ListItem>

                <ListItemText primary={TranslatorUtil.t("Download messages export")+" ("+messagesExport.length+")"} />
                <ListItemIcon>
                <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Download messages export")}>
              <CSVLink data={messagesExport}
                headers={this.getExportHeaders("messages")}
                target="_blank"
                filename={this.getExportFileName("messages")}>
                <IconButton><CloudDownloadIcon size="small" /></IconButton></CSVLink>
            </Tooltip>
                </ListItemIcon>
              </ListItem>

            </List>
            <Divider />
          </Paper>
          </div>


        </React.Fragment>}
      </div>
    );
  }
}




const mapStateToProps = (state) => ({
  selectedProject: state.selectedProject,
  userInfo: state.userInfo,
  messages: state.messages,
  profiles: state.profiles,
  discussionLikes: state.discussionLikes,
  companyUsersTags: state.userTags
});

const mapDispatchToProps = dispatch =>
({
  actions: bindActionCreators(
    {
      ...projectActions,
      ...discussionActions,
      ...notificationActions
    },
    dispatch
  )
});


export default withRouter(withSnackbar(withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ExportDiscussion)
)));