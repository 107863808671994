import React from "react";
import { withStyles } from '@material-ui/core/styles';
import styles from "../../theme/styles";
import { NavLink } from "react-router-dom";
import UrlBuilderUtil from "../../utils/projectBased/UrlBuilderUtil";
import TranslatorUtil from "../../utils/TranslatorUtil";
import { Box } from "@material-ui/core";
import PageHeading from "../../components/PageHeading";
import Logo from "../../components/Logo";

class Welcome extends React.Component {



  render() {


    return <div>
        <Box px={1} className="logoOuterWrap narrowMaxMediumWidthPart">
        <div className="logoWrap">
          <Logo />
        </div>
        <PageHeading heading={TranslatorUtil.t('Welcome back')} />
        </Box>
        <div className="formLinksWrap">
      <NavLink className="linkBtn" to={UrlBuilderUtil.getRegistration()} >{TranslatorUtil.t("Sign in")}</NavLink>
      <NavLink className="linkBtn" to={UrlBuilderUtil.getLogin()} >{TranslatorUtil.t("Login")}</NavLink>
      </div>
    </div>
  }
}

export default withStyles(styles, { withTheme: true })(Welcome);