import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import DashboardIcon from '@material-ui/icons/Dashboard';

import styles from "../../../theme/styles";
import Logo from "../../Logo";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import { Badge } from "@material-ui/core";
import { NavLink } from "react-router-dom";

class MobileNavBar extends React.Component {

  renderDashboardMenuItem = (notifications) => {
    var unreadCount = notifications.filter(x => !x.read).length;

      return <NavLink to={'/dashboard/'}>{
        unreadCount > 0 ? <Badge className="badge zoominout-badge" badgeContent={unreadCount} color="error"><DashboardIcon color="primary" /></Badge> : <DashboardIcon color="primary" />
      }</NavLink>;
    
  }

  render() {
    const { classes, handleDrawerOpen, handleDrawerClose, drawerOpen, notifications } = this.props;

    

    return (
      <AppBar
        position="static"
        className={classes.appBar}
      >
        <Toolbar >
      <Logo inNavbar={true} />
      <div className={classes.menuButton}>
      {this.renderDashboardMenuItem(notifications)}
      <IconButton
            color="inherit"
            aria-label={TranslatorUtil.t("Open drawer")}
            onClick={drawerOpen ? handleDrawerClose : handleDrawerOpen}
          >
            {drawerOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton> 
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

MobileNavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(MobileNavBar);
