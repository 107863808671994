import React from "react";
import { withRouter, NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as previewActions from "../../../actions/preview";
import * as accessActions from "../../../actions/access";
import * as constants from '../../../constants/constants';
import * as notificationActions from "../../../actions/notification";
import ComponentLoading from "../../../components/ComponentLoading";
import { withStyles } from '@material-ui/core/styles';
import styles from "../../../theme/styles";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import objectPath from "object-path";
import { Alert, AlertTitle } from "@material-ui/lab";
import ProjectPreviewCard from "../../../components/project/ProjectPreviewCard";
import CompanyUtil from "../../../utils/projectBased/CompanyUtil";
import PageHeading from "../../../components/PageHeading";
import NotificationForm from "../../../forms/notification/form";
import HeadingBackBtn from "../../../components/buttons/HeadingBackBtn";
import ArrayUtil from "../../../utils/ArrayUtil";
import AccessUtil from "../../../utils/projectBased/AccessUtil";
import { Grid, Hidden } from "@material-ui/core";
import SnackBarUtil from "../../../utils/SnackBarUtil";
import EmailUtil from "../../../utils/projectBased/EmailUtil";
import { withSnackbar } from "notistack";

const entityListUrl = "/projects/";

class ProjectNotify extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        project: null,
        notificationMessage: null,
        notificationSubject: null,
        rolesToNotify: null,
        dataLoading: true,
        projectAccess: [],
        processing: false,
    }
  }


  getProject = () => {
      const { actions, match } = this.props;
    return actions.getProjectPreview(
        CompanyUtil.getId(),
      objectPath.get(match,'params.projectId',null)
      ).then((response) => this.setState({project: objectPath.get(response,'data')}));
    }


  componentDidMount() {
    var _this = this;
      const { match } = this.props;
      
      if( AccessUtil.isAtLeastModeratorForProject(objectPath.get(match,'params.projectId',null), true) ){
        Promise.all([
          this.getProject()
        ]).then(()=> _this.setState({dataLoading: false}));
      }
  }

  componentWillUnmount(){
      
  }


  handleSubmit = values => {
    this.setState({
      processing: true
    });
    const { actions, enqueueSnackbar } = this.props;
    const {  project } = this.state;
    var allEmails = [];
    var roleToNotify = objectPath.get(values,"role","");
    console.log('roleToNotify',roleToNotify);
    if(roleToNotify !== ""){
      allEmails = AccessUtil.getRoleAccessByProject(project.id,roleToNotify);
    }else{
      //send to all
      [constants.RESPONDENTS,constants.OBSERVERS,constants.MODERATORS].forEach(role => {
        allEmails = allEmails.concat(AccessUtil.getRoleAccessByProject(project.id,role));
      });
    }
    
    //add company admins
    if(roleToNotify === "" || roleToNotify === constants.MODERATORS){
      allEmails = allEmails.concat(CompanyUtil.getCurrentCompanyAdminEmails());
    }
    //removeDuplications
    allEmails = ArrayUtil.removeDuplicates(allEmails);
    
    
    if(allEmails.length){
      console.log('notify',CompanyUtil.getId(),project.id,allEmails,values.subject,values.message);
      return Promise.all(allEmails.map(email => actions.postNotification(EmailUtil.getCustomProjectNotification(
        CompanyUtil.getId(),project.id,email,values.subject,values.message
      )))).then(response => {
        this.handleResponse(response, values, roleToNotify)
        this.setState({
          processing: false
        });
      });
    }
    else {
      enqueueSnackbar(TranslatorUtil.t("No users to notify."), {
        variant: "error"
      });
      this.setState({
        processing: false
      });
      return Promise.resolve();
    }
    
  };
  

  handleResponse = (response, values, roleToNotify) => {
    const { enqueueSnackbar,history } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      var successMessage = "\""+values.subject+"\" "+ TranslatorUtil.t("notification sent to")+" "+(roleToNotify !== "" ? TranslatorUtil.t(roleToNotify) : TranslatorUtil.t("all project users"));
      enqueueSnackbar(successMessage, {
        variant: "success"
      });
      history.push(entityListUrl);
    }
  }




  render() {
    const { dataLoading, project, processing } = this.state;


    return <div className="project-notify-wrap">
        <PageHeading heading={(TranslatorUtil.t("Notify users of "))+(dataLoading ? '...' : project ? project.name : TranslatorUtil.t("Not found"))} actions={
          <div>
            <HeadingBackBtn redirectToUrl={entityListUrl} />
        </div>
        } />
        <div>
            {dataLoading ? <ComponentLoading /> : (project ? <div>
                <Grid container>
                <Grid item xs={12} lg={6}>
                        <NotificationForm 
                            proceedSubmit={this.handleSubmit} 
                            submitting={processing}
                            roles={["all",constants.RESPONDENTS,constants.OBSERVERS,constants.MODERATORS]}
                        /> 
                </Grid>
                <Hidden mdDown>
                <Grid item xs={12} lg={6}>
                <ProjectPreviewCard 
                company={CompanyUtil.get()}
                hideTerms={true}
                project={project} />
                </Grid>
                </Hidden>
               
          
          </Grid>
                </div> : <div><Alert severity="error">
                <AlertTitle>{TranslatorUtil.t("Error")}</AlertTitle>
                      {TranslatorUtil.t("Unfortunatelly something went wrong and invitation has expired or is not valid anymore")}
                          
                    </Alert>
                    <br />
                    <NavLink className="linkBtn" to={"/"}>{TranslatorUtil.t('Continue here')}</NavLink>
                    </div>)
        
        }
          </div>
    </div>
  }
}


function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    profiles: state.profiles,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...previewActions,
        ...accessActions,
        ...notificationActions
      },
      dispatch
    )
  };
}



export default withRouter(withSnackbar(withStyles(styles, { withTheme: true })(connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectNotify))));
