import moment from 'moment';
import * as constants from '../constants/constants';
import 'moment-timezone';
import ArrayUtil from './ArrayUtil';
import { Hidden } from '@material-ui/core';
import React from 'react';
import 'moment/locale/en-ie';
import 'moment/locale/cs';

export default class DateUtil {

    static responsiveDate(date, time){
      return <span>
        <Hidden smUp>{DateUtil.basicDateSlashes(time)}</Hidden><Hidden xsDown>{date}</Hidden>
      </span>
    }

    static localizedTime(time = null, timezone = "CET"){
      var momentTime = time ? moment(time) : moment();
      return moment(momentTime).tz(timezone).format("HH:mm DD MMM YYYY");
    }

    static daysInMiliseconds(){
      return 86400000;
    }

    static displayTimeFromNow(time = null, lang = constants.DEFAULT_TRANSLATION_LANG, timezone = "CET"){
      var momentTime = time ? moment(time) : moment();
      var fromNow = moment(momentTime).tz(timezone).lang(lang === 'en' ? 'en-ie' : lang).fromNow();
      console.log('from now '+lang,fromNow);
      if(Math.abs(DateUtil.unixTime(time) - DateUtil.unixTime()) < DateUtil.daysInMiliseconds()){
        return fromNow;
      }
      return DateUtil.localizedTime(time,timezone);
    }

    static unixTime(time = null, timezone = "CET") {
      return time ? moment(time).tz(timezone).valueOf() : moment().valueOf();
    }

    static getTimezoneTime(time = null, timezone = "CET") {
      return time ? moment(time).tz(timezone) : moment();
    }

    static stripDateFromTime(date){
      return DateUtil.backendDateFormat(date);
    }

    static basicDate(time, responsive = false) {
      return responsive ? DateUtil.responsiveDate(moment(time).format("DD MMM YYYY"), time) : moment(time).format("DD MMM YYYY");
    }

    static basicDateWithWeekday(time) {
      return moment(time).format("ddd DD MMM YYYY");
    }

    static basicDateWithoutYear(time) {
      return moment(time).format("DD MMM YY");
    }

    static formatForSorting(date) {
      date = date ? moment(date) : moment();
      return date.format("YYYYMMDD");
    }

    static basicDateSlashes(time) {
      return moment(time).format("DD/MM/YY");
    }

    static fullNameMonthDate(time) {
      return moment(time).format("DD MMMM YYYY");
    }

    static backendDateFormat(date = null){
      var momentDate = date ? moment(date) : moment();
      return momentDate.format('YYYY-MM-DD');
    }

    static backendTimeFormat(time = null){
      var momentTime = time ? moment(time) : moment();
      return momentTime.format('HH:mm');
    }

    static sortArrayASC(array){
      return Array.isArray(array) ? array.sort(function (a, b) { //sort

        return moment(a).diff(moment(b))
      }) : array;
    }

    static isPresentOrFuture(date){
      return moment(date).isSameOrAfter(moment());
    }

    static isDateSelected(date, selectedValue){
      if(ArrayUtil.isNonEmptyArray(selectedValue)){
        return selectedValue.includes(DateUtil.backendDateFormat(date));
      }else{
        return selectedValue === DateUtil.backendDateFormat(date);
      }
    }


    static isBeforeNow(date,time){
      if(date && time){
        var m = moment.utc(date + ' ' + time+":00", "YYYY-MM-DD  HH:mm:ss");
        return m.isValid() && moment().isAfter(m);
      }
      return false;
    }

    static timestamp(){
      return DateUtil.unixTime();///Timestamp.now().toMillis();
    }
}
