import React from "react";
import { withStyles } from "@material-ui/core/styles";
import * as constants from "../../constants/constants";
import UsersUtil from "../../utils/projectBased/UsersUtil";
import styles from "../../theme/styles";
import { Tooltip, Avatar, Menu, MenuItem, ListItemIcon, Typography, Badge } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import UserDialog from "./UserDialog";
import ChatIcon from '@material-ui/icons/Chat';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import TranslatorUtil from "../../utils/TranslatorUtil";
import UrlBuilderUtil from "../../utils/projectBased/UrlBuilderUtil";
import objectPath from "object-path";
import HelpIcon from '@material-ui/icons/Help';
import FormatterUtil from "../../utils/FormatterUtil";
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import FeedbackIcon from '@material-ui/icons/Feedback';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import AccessUtil from "../../utils/projectBased/AccessUtil";
import store from "../../store";

class UserAvatar extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            showSubmenu: false,
            showDialog: false,
            anchorEl: null
        }
      }

    

      toggleSubmenu = (event) => {
        const { userId } = this.props;
        return UsersUtil.userExistsAndNotCurrent(userId) ? this.setState({
            anchorEl: event.currentTarget,
            showSubmenu: !this.state.showSubmenu
        }) : null;
      }

      chatAboutMessage = () => {
        const { menuItems, history } = this.props;
        var chatAboutMessage = objectPath.get(menuItems,'withChatAboutDiscussionMessage');
        if(chatAboutMessage){
          history.push(UrlBuilderUtil.getChatAboutMessage(
            chatAboutMessage.message.byUserId,
            chatAboutMessage.projectId,
            chatAboutMessage.discussionId,
            chatAboutMessage.message.id
            )
            );
        }
        
      }




      toggleDialog = () => {
        const { userId } = this.props;
        return UsersUtil.userExistsAndNotCurrent(userId) ? this.setState({
            showSubmenu: false,
            showDialog: !this.state.showDialog
        }) : null;
      }


      renderSubmenu = () => {
        const { anchorEl } = this.state;
        const { userId, menuItems, history } = this.props;

        var anyTrue = typeof menuItems === 'object' && Object.keys(menuItems).find(x => objectPath.get(menuItems,x) === true);

        return anyTrue ? <Menu
                    anchorEl={anchorEl}
                    id="user-submenu"
                    open={true}
                    onClose={this.toggleSubmenu}
                    >
            {objectPath.get(menuItems,'withDialog') ? <MenuItem onClick={this.toggleDialog}>
            <ListItemIcon>
            <AccountBoxIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">{TranslatorUtil.t("User profile")}</Typography>
                
                </MenuItem> : null }
                {objectPath.get(menuItems,'withChat') ? <MenuItem onClick={() => history.push(UrlBuilderUtil.getChat(userId))}>
            <ListItemIcon>
            <ChatIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">{TranslatorUtil.t("Chat")}</Typography></MenuItem> : null }

          {objectPath.get(menuItems,'withChatAboutDiscussionMessage') ? <MenuItem onClick={this.chatAboutMessage}>
            <ListItemIcon>
            <FeedbackIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">{TranslatorUtil.t("Chat about this message")}</Typography></MenuItem> : null }
        </Menu> : null

      }

    render() {
        const { showSubmenu, showDialog } = this.state;
        const { userId, anonymous } = this.props;
        const userEmail = UsersUtil.getUserEmailFromStoreById(userId);
        const projectId = store.getState().selectedProject?.id;
        const moderator = projectId ? AccessUtil.getModeratorsAccessByProject(projectId).includes(userEmail) : false;
        return  (
            <React.Fragment>
              {
                anonymous ? <Badge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  badgeContent={<HelpIcon size="small" />}
                >
                  <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("anonym")+"_"+FormatterUtil.hashCodeShort(userId)}>
                    <Avatar alt={UsersUtil.getUserDisplayNameById(userId)}>
                      <PersonOutlineIcon size="large" />
                    </Avatar>
                  </Tooltip>
                </Badge> : moderator ? <Badge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  badgeContent={<HeadsetMicIcon size="small" />}
                >
                  <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={UsersUtil.getUserDisplayNameById(userId)}>
                    <Avatar alt={UsersUtil.getUserDisplayNameById(userId)} src={UsersUtil.getAvatarUrlById(userId)} onClick={this.toggleSubmenu} />
                  </Tooltip>
                </Badge> :
                <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={UsersUtil.getUserDisplayNameById(userId)}>
                  <Avatar alt={UsersUtil.getUserDisplayNameById(userId)} src={UsersUtil.getAvatarUrlById(userId)} onClick={this.toggleSubmenu} />
                </Tooltip>
              }
            
            {
                showSubmenu ? this.renderSubmenu() : null
            }
            {
                showDialog ? <UserDialog userId={userId} onClose={this.toggleDialog} /> : null
            }
            </React.Fragment>
          );
    }
}


export default withRouter(withStyles(styles, { withTheme: true })(UserAvatar));


