import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import UserRegistrationForm from "../../../forms/user/registration/form";
import * as firebaseUserActions from "../../../actions/firebaseUser";
import * as formNames from "../../../constants/forms";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";

import SnackBarUtil from '../../../utils/SnackBarUtil';
import ComponentLoading from "../../../components/ComponentLoading";
import { change } from 'redux-form';
import UrlBuilderUtil from '../../../utils/projectBased/UrlBuilderUtil';
import { NavLink } from "react-router-dom";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import { Alert } from "@material-ui/lab";
import { Box } from "@material-ui/core";
import PageHeading from "../../../components/PageHeading";
import EmailUtil from "../../../utils/projectBased/EmailUtil";
import * as notificationActions from "../../../actions/notification";
import Logo from "../../../components/Logo";
const entityFormName = formNames.REGISTRATION_FORM_NAME;


class Registration extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      dataLoading: true
    }
  }

  componentDidMount(){
    Promise.all([
    ]).then(() =>
      this.setState({
        dataLoading: false
      })
      );
  }



  handleResponse = response => {
    const { enqueueSnackbar, history } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      var successMessage = TranslatorUtil.t("You are logged in");
      enqueueSnackbar(successMessage, {
        variant: "success"
      });
      
      history.push(UrlBuilderUtil.getDashboard());
    }
  }

  handleSubmit = values => {
    const { actions } = this.props;
    //has to return promise so submitting flag works!
    return actions.userRegistration(values).then(response => {
      actions.postNotification(EmailUtil.getRegisterationNotificationEmailObject(values.email));
      this.handleResponse(response);
    });
  };


  render() {
    const { dataLoading } = this.state;
    

    return (
      <div>

      { dataLoading ? <ComponentLoading /> : 
      <div>
        <Box px={1} className="logoOuterWrap narrowMaxMediumWidthPart">
        <div className="logoWrap">
          <Logo />
        </div>
        <PageHeading heading={TranslatorUtil.t('Sign in')} />
        </Box>
        <UserRegistrationForm 
        proceedSubmit={this.handleSubmit} 
        //initialValues={}
      />
      <div className="formLinksWrap">
      <Box px={1} pb={2} className="narrowMaxMediumWidthPart">
      <Alert severity="info">{TranslatorUtil.t("If you already have an account go straight to login below")}</Alert>
      </Box>
      <NavLink className="linkBtn" to={UrlBuilderUtil.getLogin()} >{TranslatorUtil.t("Login")}</NavLink>
      </div></div> }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = dispatch =>
({
  changeFieldValue: function(field, value) {
    dispatch(change(entityFormName, field, value))
  },
  actions: bindActionCreators(
    {
      ...firebaseUserActions,
      ...notificationActions
    },
    dispatch
  )
});


export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Registration)
));