import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import Drawer from "@material-ui/core/Drawer";
import MenuItem from "@material-ui/core/MenuItem";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import BusinessIcon from '@material-ui/icons/Business';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { connect } from "react-redux";



import styles from "../../../theme/styles";


//config
import { Divider, Grid, MenuList, Avatar, Box, Badge } from "@material-ui/core";
import DrawerMenuItem from "../AppMenuItem";
import Logo from "../../Logo";
import UrlBuilderUtil from "../../../utils/projectBased/UrlBuilderUtil";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import UsersUtil from "../../../utils/projectBased/UsersUtil";
import objectPath from "object-path";
import AccessUtil from '../../../utils/projectBased/AccessUtil';
import LockIcon from '@material-ui/icons/Lock';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

class DesktopDrawer extends React.Component {

  renderMenuLinks = (items, showIcon) => {
    const {
      userInfo,
    } = this.props;

    return items.map((m, index) => {
      return <DrawerMenuItem menuItem={m} key={index} showIcon={showIcon} userInfo={userInfo} />
    })
  }

  renderSelectedProjectMenuItem = (userInfo) => {
    const {
      selectedProject
    } = this.props;
    if(selectedProject){
      var selectedProjectMenuItem = {
        label: selectedProject.name,
        path: UrlBuilderUtil.getProjectDashboard(selectedProject.id),
        showIcon: true,
        icon: AccessUtil.isProjectLocked(selectedProject) ? <LockIcon /> : <AssignmentTurnedInIcon />
        
      }
      return <DrawerMenuItem menuItem={selectedProjectMenuItem} showIcon={true} userInfo={userInfo} />;
    }
    return null;
  }

  renderProfileMenuItem = (userInfo) => {
    const {
      classes
    } = this.props;
    if(userInfo){
      var profileMenuItem = {
        label: UsersUtil.getUserDisplayNameByUserInfo(userInfo),
        path: UrlBuilderUtil.getProfileEdit(),
        showIcon: true,
        icon: <Avatar className={classes.menuItemAvatar} src={UsersUtil.getAvatarUrlByAvatarId(objectPath.get(userInfo,'profile.avatar'))} />
      }

      return <DrawerMenuItem menuItem={profileMenuItem} showIcon={true} userInfo={userInfo} />;
    }
    return null;
  }

  renderCompanyMenuItem = (userInfo) => {
    if(AccessUtil.isCompanyAdmin()){
      var companyMenuItem = {
        label: TranslatorUtil.t('company'),
        path: UrlBuilderUtil.getCompany(),
        showIcon: true,
        icon: <BusinessIcon />
      }

      return <DrawerMenuItem menuItem={companyMenuItem} showIcon={true} userInfo={userInfo} />;
    }
    return null;
  }

  renderDashboardMenuItem = (userInfo) => {
    const {
      notifications
    } = this.props;
    var unreadCount = notifications.filter(x => !x.read).length;
      var menuItem = {
          label: "Dashboard",
          path: '/dashboard/',
          showIcon: true,
          icon: (unreadCount > 0 ? <Badge className="badge zoominout-badge" badgeContent={unreadCount} color="error"><DashboardIcon /></Badge> : <DashboardIcon />)
          
      };

      return <DrawerMenuItem menuItem={menuItem} showIcon={true} userInfo={userInfo} />;
    
  }

  render() {
    const {
      menu,
      classes,
      userInfo,
            //theme,
      handleLogout,
    } = this.props;


    return (
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={true}
        classes={{
          root: classnames(classes.drawerRoot, "hideForPrint"),
          paper: classnames(classes.drawerPaper, classes.drawerPaperLevel_1)
        }}
      >
        <div className="logoWrap">
          <Logo />
        </div>
        <Grid container className={classes.drawerGridContainer}>
          <Grid item xs={12}>
            <MenuList>
              <Divider />
              {this.renderSelectedProjectMenuItem(userInfo)}
              <Divider />
              {this.renderDashboardMenuItem(userInfo)}
              {this.renderMenuLinks(menu.primaryMenu, true)}
              <Divider />
              {this.renderMenuLinks(menu.secondaryMenu, true)}
              <Divider />
              {this.renderProfileMenuItem(userInfo)}
              {this.renderCompanyMenuItem(userInfo)}
              <Divider />
              <MenuItem onClick={handleLogout}  className={classes.menuItem}>
                <Box className={"menuItemTextBtn"}>
                  <LogoutIcon fontSize="small" className={classes.menuItemIcon} />
                  <Box className={classes.menuItemText}>
                  {TranslatorUtil.t('Logout')}
                  </Box>
                </Box>
              </MenuItem>
            </MenuList>
          </Grid>
        </Grid>

      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
   //force rerender on location and dashboard data change
  routerLocations: state.routerLocations,
  notifications: state.notifications
});

export default 
  connect(
    mapStateToProps
  )(
    withStyles(styles, { withTheme: true })(DesktopDrawer)
  );

