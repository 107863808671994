import objectPath from "object-path";
import UrlBuilderUtil from "../utils/projectBased/UrlBuilderUtil";
import TranslatorUtil from "../utils/TranslatorUtil";

export const MAIL_TYPES = {
    INVITATION: {
        ID: 1,
        BODY : (companyId, project, roleType) => {
            return TranslatorUtil.t("name: {:projectName} description: {:projectDescription}", {
                projectName: objectPath.get(project,'name',''),
                projectDescription: objectPath.get(project,'description',''),
                projectUrl: UrlBuilderUtil.createInvitationLink(companyId, objectPath.get(project,'id',0)),
                roleType: TranslatorUtil.t(roleType)
            });
        },
        BODY_HTML : (companyId, project,roleType) => {
            return TranslatorUtil.t("name: {:projectName}<br />description: {:projectDescription}", {
                projectName: objectPath.get(project,'name',''),
                projectDescription: objectPath.get(project,'description',''),
                projectUrl: UrlBuilderUtil.createInvitationLink(companyId, objectPath.get(project,'id',0)),
                roleType: TranslatorUtil.t(roleType)
            });
        },
        SUBJECT : (project,roleType) => {
            return TranslatorUtil.t("Project invitation {:projectName}", {
                projectName: objectPath.get(project,'name','')
            });
        },
        ACTION_URL : (companyId, project,roleType) => {
            return UrlBuilderUtil.createInvitationLink(companyId, objectPath.get(project,'id',0))
        }
    },

    INVITATION_ACCEPTED: {
        ID: 2,
        BODY : (project, email, roleType) => {
            return TranslatorUtil.t("name: {:projectName} email: {:email} role: {:roleType}", {
                projectName: objectPath.get(project,'name',''),
                email: email,
                roleType: TranslatorUtil.t(roleType)
            });
        },
        BODY_HTML : (project,email,roleType) => {
            return TranslatorUtil.tb("name: {:projectName}<br />invitation accepted by: {:email}<br />role: {:roleType}", {
                projectName: objectPath.get(project,'name',''),
                email: email,
                projectUrl: UrlBuilderUtil.getRquestedHostUrl()+UrlBuilderUtil.getProjectPreview(objectPath.get(project,'id',0)),
                roleType: TranslatorUtil.t(roleType)
            });
        },
        SUBJECT : (project,roleType) => {
            return TranslatorUtil.t("Invitation accepted - {:projectName}", {
                projectName: objectPath.get(project,'name','')
            });
        },
        ACTION_URL : (project,email,roleType) => {
            return UrlBuilderUtil.getRquestedHostUrl()+UrlBuilderUtil.getProjectPreview(objectPath.get(project,'id',0));
        }
    },

    REGISTRATION: {
        ID: 3,
        BODY : (email) => {
            return TranslatorUtil.t("Profile registration : {:email}", {
                email: email,
                profileUrl: UrlBuilderUtil.getRquestedHostUrl()+UrlBuilderUtil.getProfileEdit()
            });
        },
        BODY_HTML : (email) => {
            return TranslatorUtil.tb("Profile registration : {:email}", {
                email: email,
                profileUrl: UrlBuilderUtil.getRquestedHostUrl()+UrlBuilderUtil.getProfileEdit()
            });
        },
        SUBJECT : () => {
            return TranslatorUtil.t("Profile registration");
        },
        ACTION_URL : () => {
            return UrlBuilderUtil.getRquestedHostUrl()+UrlBuilderUtil.getProfileEdit();
        }
    },

    DISCUSSION_ADDED: {
        ID: 4,
        BODY : (project,discussion) => {
            return TranslatorUtil.t("Discussion {:discussionName} has been added to {:projectName}", {
                discussionName: objectPath.get(discussion,'name',''),
                discussionIntroduction: objectPath.get(discussion,'description',''),
                projectName: objectPath.get(project,'name',''),
                projectUrl: UrlBuilderUtil.getRquestedHostUrl()+UrlBuilderUtil.getProjectDashboard(objectPath.get(project,'id',0)),
            });
        },
        BODY_HTML : (project,discussion) => {
            return TranslatorUtil.tb("Discussion {:discussionName} has been added to {:projectName} {:discussionIntroduction}", {
                discussionName: objectPath.get(discussion,'name',''),
                discussionIntroduction: objectPath.get(discussion,'description',''),
                projectName: objectPath.get(project,'name','')
            });
        },
        SUBJECT : (project,discussion) => {
            return TranslatorUtil.t("Discussion {:discussionName} has been added",{
                discussionName: objectPath.get(discussion,'name','')
            });
        },
        ACTION_URL : (project,discussion) => {
            return UrlBuilderUtil.getRquestedHostUrl()+UrlBuilderUtil.getProjectDashboard(objectPath.get(project,'id',0));
        }
    },

    DISCUSSION_REACTION: {
        ID: 5,
        BODY : (project,discussion,message, reply) => {
            var projectId = objectPath.get(project,'id',0);
            var discussionId = objectPath.get(discussion,'id',0);
            var messageId = objectPath.get(message,'id',0);
            return TranslatorUtil.t("Your message {:messageText} in {:discussionName} has new reaction {:replyText}", {
                discussionName: objectPath.get(discussion,'name',''),
                messageText: objectPath.get(message,'message',''),
                replyText: objectPath.get(reply,'message',''),
                discussionUrl: UrlBuilderUtil.getRquestedHostUrl()+UrlBuilderUtil.getDiscussionConversation(projectId,discussionId,messageId),
            });
        },
        BODY_HTML : (project,discussion,message, reply) => {
            var projectId = objectPath.get(project,'id',0);
            var discussionId = objectPath.get(discussion,'id',0);
            var messageId = objectPath.get(message,'id',0);
            return TranslatorUtil.tb("Your message {:messageText} in {:discussionName} has new reaction {:replyText}", {
                discussionName: objectPath.get(discussion,'name',''),
                messageText: objectPath.get(message,'message',''),
                replyText: objectPath.get(reply,'message',''),
                discussionUrl: UrlBuilderUtil.getRquestedHostUrl()+UrlBuilderUtil.getDiscussionConversation(projectId,discussionId,messageId),
            });
        },
        SUBJECT : (project,discussion, message, reply) => {
            return TranslatorUtil.t("New reaction {:discussionName} has been added",{
                discussionName: objectPath.get(discussion,'name','')
            });
        },
        ACTION_URL : (project,discussion,message, reply) => {
            var projectId = objectPath.get(project,'id',0);
            var discussionId = objectPath.get(discussion,'id',0);
            var messageId = objectPath.get(message,'id',0);
            return UrlBuilderUtil.getRquestedHostUrl()+UrlBuilderUtil.getDiscussionConversation(projectId,discussionId,messageId);
        }
    },


    RESPONDENT_PROFILE_UPDATE: {
        ID: 6,
        BODY : (profile) => {
            return TranslatorUtil.t("Profile updated by user {:profileEmail}", {
                profileEmail: objectPath.get(profile,'email',''),
            });
        },
        BODY_HTML : (profile) => {
            return TranslatorUtil.tb("Profile updated by user {:profileEmail}", {
                profileEmail: objectPath.get(profile,'email',''),
            });
        },
        SUBJECT : (profile) => {
            return TranslatorUtil.t("Profile updated by respondent");
        },
        ACTION_URL : () => {
            return null;
        }
    },

    CUSTOM_PROJECT_NOTIFICATION: {
        ID: 7,
        ACTION_URL : (projectId) => {
            return UrlBuilderUtil.getRquestedHostUrl()+UrlBuilderUtil.getProjectDashboard(projectId);
        }
    },


    CHAT_NOTIFICATION: {
        ID: 8,
        BODY : (message,fromName,aboutMessage) => {
            return TranslatorUtil.t("New message from user {:fromName} - {:message}", {
                fromName: fromName,
                message: message.message
            });
        },
        BODY_HTML : (message,fromName,aboutMessage) => {
            return TranslatorUtil.tb("New message from user {:fromName} - {:message}", {
                fromName: fromName,
                message:message.message
            });
        },
        SUBJECT : (message,fromName,aboutMessage) => {
            return TranslatorUtil.t("New message");
        },
        ACTION_URL : (toUserId) => {
            return UrlBuilderUtil.getRquestedHostUrl()+UrlBuilderUtil.getChat(toUserId);
        }
    },

};
