import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import UserLoginForm from "../../../forms/user/login/form";
import * as firebaseUserActions from "../../../actions/firebaseUser";
import * as formNames from "../../../constants/forms";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";
import { Box } from "@material-ui/core";
import SnackBarUtil from '../../../utils/SnackBarUtil';
import ComponentLoading from "../../../components/ComponentLoading";
import { change } from 'redux-form';
import UrlBuilderUtil from '../../../utils/projectBased/UrlBuilderUtil';
import { NavLink } from "react-router-dom";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import PageHeading from "../../../components/PageHeading";
import Logo from "../../../components/Logo";
const entityFormName = formNames.LOGIN_FORM_NAME;


class Login extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      dataLoading: true
    }
  }

  componentDidMount(){
    //const { actions } = this.props;
    Promise.all([
    ]).then(() =>
      this.setState({
        dataLoading: false
      })
      );
  }



  handleResponse = response => {
    const { enqueueSnackbar, history } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      var successMessage = TranslatorUtil.t("You are logged in");
      enqueueSnackbar(successMessage, {
        variant: "success"
      });
      history.push(UrlBuilderUtil.getDashboard());
    }
  }

  handleSubmit = values => {
    const { actions } = this.props;
    //has to return promise so submitting flag works!
    return actions.loginWithEmail(values).then(response => this.handleResponse(response));
  };

  render() {
    const { dataLoading } = this.state;
    

    return (
      <div>

      { dataLoading ? <ComponentLoading /> : 
      
      <div>
        <Box px={1} className="logoOuterWrap narrowMaxMediumWidthPart">
        <div className="logoWrap">
          <Logo />
        </div>
        <PageHeading heading={TranslatorUtil.t('Login')} />
        </Box>
        <UserLoginForm 
      proceedSubmit={this.handleSubmit} 
    /> 
    <div className="formLinksWrap">
    <Box  px={1} pb={2} className="narrowMaxMediumWidthPart">
      <Alert severity="info">{TranslatorUtil.t("Please continue below if you forgot your password or do not have account")}</Alert>
      </Box>
      <NavLink className="linkBtn" to={UrlBuilderUtil.getRegistration()} >{TranslatorUtil.t("Sign in")}</NavLink>
      <NavLink className="linkBtn" to={UrlBuilderUtil.getResetPassword()} >{TranslatorUtil.t("Reset password")}</NavLink>
      </div>
      </div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = dispatch =>
({
  changeFieldValue: function(field, value) {
    dispatch(change(entityFormName, field, value))
  },
  actions: bindActionCreators(
    {
      ...firebaseUserActions,
    },
    dispatch
  )
});


export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
));