import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import { Typography, ListItem, ListItemAvatar, Paper, ListItemText, Grid, Button, ButtonGroup, Hidden } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import DateUtil from '../../utils/DateUtil';
import UserAvatar from '../user/UserAvatar';
import ThumbUpAltRoundedIcon from '@material-ui/icons/ThumbUpAltRounded';
import ThumbDownRoundedIcon from '@material-ui/icons/ThumbDownRounded';
import objectPath from 'object-path';
import ReplyIcon from '@material-ui/icons/Reply';
import UrlBuilderUtil from '../../utils/projectBased/UrlBuilderUtil';
import AccessUtil from '../../utils/projectBased/AccessUtil';
import UsersUtil from '../../utils/projectBased/UsersUtil';
import TranslatorUtil from '../../utils/TranslatorUtil';


class MessageItem extends React.Component {


  renderListText = () => {
    const { classes, message, projectId} = this.props;    

    const isByModerator = AccessUtil.isUserAtLeastModeratorForProject(projectId,UsersUtil.getUserEmailFromStoreById(message.byUserId));

    return <ListItemText
      secondaryTypographyProps={{ component: "div" }}
      className={isByModerator ? "messageContentWrap moderatorMessage" : "messageContentWrap"}
      secondary={
        <Paper elevation={1} className={"p-2 messagePaper"} component="div" style={{ display: "block" }}>
          <Typography component="div" className={classes.inline} color="textPrimary">

            {message.message}
          </Typography>
          <Grid container>
            <Grid item xs={6}>
              <small className="messageDate">{DateUtil.displayTimeFromNow(message.createdTime, TranslatorUtil.getUserLangFromStore())}</small>
            </Grid>
            <Grid item xs={6} className="likeBtns">
              { this.renderReactions() }  
            </Grid>
          </Grid>

        </Paper>
      }
    />
  }

  renderReactions = () => {
    const { message, currentUserId, onLikeDislike, likes, onMessageReply, disableReactions, onReplyText } = this.props;
    
    if(likes){
      var messageLikes = {
        id: message.id,
        likes:objectPath.get(likes.find(x => x.id === message.id), 'likes', []),
        dislikes:objectPath.get(likes.find(x => x.id === message.id), 'dislikes', []),
      };


      const likesCount = objectPath.get(messageLikes, 'likes', []).length;
      const hasUserLiked = objectPath.get(messageLikes,'likes', []).includes(currentUserId);
      const dislikesCount = objectPath.get(messageLikes, 'dislikes', []).length;
      const hasUserDisliked = objectPath.get(messageLikes, 'dislikes', []).includes(currentUserId);

      return <ButtonGroup>
    { onMessageReply && !disableReactions ? <Button
        variant="text"
        size="small"
        onClick={() => onMessageReply(message.id)}
        startIcon={<ReplyIcon />}
      >
        <Hidden lgDown>{onReplyText}</Hidden>
      </Button> : null }
      <Button
        variant="text"
        size="small"
        disabled={!onLikeDislike || hasUserDisliked || disableReactions}
        className={"likeBtn "+(hasUserLiked ? "liked" : "")}
        onClick={() => onLikeDislike ? onLikeDislike(messageLikes,true) : null }
        startIcon={<ThumbUpAltRoundedIcon />}
      >
        {likesCount ? likesCount : ''}
      </Button>
      <Button
        variant="text"
        size="small"
        disabled={!onLikeDislike || hasUserLiked || disableReactions}
        onClick={() => onLikeDislike ? onLikeDislike(messageLikes,false) : null }
        className={"dislikeBtn "+(hasUserDisliked ? "liked" : "")}
        startIcon={<ThumbDownRoundedIcon />}
      >
        {dislikesCount ? dislikesCount : ''}
      </Button>
    </ButtonGroup>
    }else{
      return null;
    }
  }

  renderAvatar = () => {
    const { message, currentUserId, match, disableAvatarActions } = this.props;
    var notCurrent = currentUserId !== message.byUserId;
    var currentProjectId = UrlBuilderUtil.getRequestedParam(match, 'projectId');
    return <ListItemAvatar className="avatar">{
      message.anonymous ? <UserAvatar anonymous={true} userId={message.byUserId} /> : <UserAvatar userId={message.byUserId}
        menuItems={ disableAvatarActions ?  {} : {
          withDialog: AccessUtil.isAtLeastObserverForProject(currentProjectId) ? notCurrent : false,
          withChat: AccessUtil.isAtLeastModeratorForProject(currentProjectId) ? notCurrent : false,
          withChatAboutDiscussionMessage: AccessUtil.isAtLeastModeratorForProject(currentProjectId) ? {
            projectId: currentProjectId,
            discussionId: UrlBuilderUtil.getRequestedParam(match, 'discussionId'),
            message
          } : false
        }
      } />
    }

    </ListItemAvatar>
  }

  highlightMessage = () => {
    const { message } = this.props;
    return objectPath.get(window,'location.hash','').indexOf(message.id) !== -1;
  }

  render() {
    const { message } = this.props;

    return message ? <ListItem alignItems="center" disableGutters={true} id={message.id} className={"messageItem "+(this.highlightMessage() ? "highlight" : "")}>
                {this.renderAvatar()}
                {this.renderListText()}
                

              </ListItem> : null
    };
}


export default withRouter(withStyles(styles, { withTheme: true })(MessageItem));