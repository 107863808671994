import objectPath from "object-path";
import * as constants from '../../constants/constants';
import DateUtil from "../DateUtil";
import FormatterUtil from "../FormatterUtil";


export default class UrlBuilderUtil {

  static getRquestedHostUrl() {
    return window.location.protocol + '//' + window.location.hostname + (window.location.port ? (':' + window.location.port) : '');
  }

  static getRequestedParam(match,paramName){
    return objectPath.get(match, "params."+paramName);
  }

  static getDashboard(){
    return '/dashboard'
  }


  static getProjectEdit(id){
    return '/projects/edit/' + id;
  }

  static getChat( id = null){
    return '/chat/' + (id ? id : '');
  }

  static getChatAboutMessage( userId, projectId, discussionId, messageId){
    return '/chat/' + userId + '/message/' + [projectId,discussionId,messageId].join('/');
  }

  static getProfileEdit( id = null){
    return '/profile/edit/' + (id ? id : '');
  }

  static getProjectPreview( id = null){
    return '/projects/preview/' + (id ? id : '');
  }
  
  static getProjectNotifyUsers(projectId){
    return '/project/'+projectId+'/notify/';
  }

  static getProjectDashboard(id){
    return '/project/' + id;
  }

  static getDiscussionCreate(projectId){
    return '/project/'+projectId+'/discussion/create/';
  }

  static getDiscussionConversation(projectId, id, messageId){
    return '/project/'+projectId+'/discussion/'+id + (messageId ? "#"+messageId : "");
  }

  static getDiscussionEdit(projectId, id){
    return '/project/'+projectId+'/discussion/edit/' + id;
  }

  static getDiscussionExport(projectId, id){
    return '/project/'+projectId+'/discussion/export/' + id;
  }
  

  static getProjectUsersEdit(projectId){
    return '/project/'+projectId+'/users/';
  }

  static getCompany(){
    return '/company/';
  }
  

  static getLogin(){
    return '/login'
  }

  static getRegistration(){
    return '/sign-in'
  }

  static getResetPassword(){
    return '/reset-password'
  }

  static createInvitationLink(companyId, projectId){

    var params = {
      companyId,
      projectId,
      expire: DateUtil.getTimezoneTime().add(7, 'days').valueOf()
    };
    return (constants.APP_DOMAIN+"invitation/"+FormatterUtil.encodeObject(params));
  }

  static getAbsoluteProjectLink(projectId){
    return (constants.APP_DOMAIN+"project/"+projectId);
  }
  




}
