import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import { Typography, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import UserAvatar from './UserAvatar';
import TranslatorUtil from '../../utils/TranslatorUtil';
import UserTags from './UserTags';
import AccessUtil from '../../utils/projectBased/AccessUtil';
import UsersUtil from '../../utils/projectBased/UsersUtil';
import IconUtil from '../../utils/projectBased/IconsUtil';


class ListUserCardsByRole extends React.Component {


    renderListText = (user) =>{
      const tags = UsersUtil.getUserTagsByUserId(user.uid);
        return <ListItemText primary={user.displayName} secondaryTypographyProps={{component:"div"}} secondary={
          <div className="border-bottom-primary">
            <Typography
              component="div"
              variant="caption"
            >
              {user.email}
            </Typography>
            {<UserTags user={user} tags={tags} />}
          </div>} />
      }
    
      renderAvatar = (user) =>{
        const { projectId } = this.props;
        return <ListItemAvatar>
          <UserAvatar userId={user.uid} 
          menuItems={{
            withChat: AccessUtil.isAtLeastModeratorForProject(projectId),
            withDialog:  AccessUtil.isAtLeastObserver(),
            
          }} />
      </ListItemAvatar>
      }

      

  render () {
  const { users, role } = this.props;

  return role && users && users.length ? (
    <List className={"narrowMaxMediumWidthPart roleUsersList"}>

          <ListItem disableGutters={false} divider={true}>
            
          <ListItemAvatar>{IconUtil.getRoleAvatar(role)}</ListItemAvatar><Typography variant="h2">{TranslatorUtil.t(role)}</Typography>
          </ListItem> 
        
        {users ? users.map((user, index) => 
          <ListItem key={index} disableGutters={true}>
            {this.renderAvatar(user)}
            {this.renderListText(user)}
        </ListItem> 
          
        ) : null}
        </List>
  ) : null;
    }
}


export default withRouter(withStyles(styles, { withTheme: true })(ListUserCardsByRole));