import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { withRouter } from "react-router-dom";  
import * as constants from '../../constants/constants';

function HeadingGeneralBtn(props) {

  const { redirectToUrl, history, title, icon } = props;

  return (
    <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={title}>
        <IconButton onClick={() => history.push(redirectToUrl)}>
        {icon}
        </IconButton>
        </Tooltip>
  );
}

export default withRouter(HeadingGeneralBtn);