import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PageHeading from "../../../components/PageHeading";

import ProfileForm from "../../../forms/profile/form";
import * as translationActions from "../../../actions/translations.js";
import * as profileActions from "../../../actions/profile.js";
import * as notificationActions from "../../../actions/notification";
import * as formNames from "../../../constants/forms";
import objectPath from 'object-path';
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";

import HeadingBackBtn from "../../../components/buttons/HeadingBackBtn";
import SnackBarUtil from '../../../utils/SnackBarUtil';
import ComponentLoading from "../../../components/ComponentLoading";
import { change } from 'redux-form';
import FormatterUtil from "../../../utils/FormatterUtil";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import AccessUtil from "../../../utils/projectBased/AccessUtil";
import EmailUtil from "../../../utils/projectBased/EmailUtil";
import CompanyUtil from "../../../utils/projectBased/CompanyUtil";

const entityName = "Profile";
const entityListUrl = "/dashboard/";
const entityFormName = formNames.PROFILE_FORM_NAME;


class EditProfile extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      item: null,
      dataLoading: true
    }
  }

  componentDidMount(){
    Promise.all([
      this.mountItem()
    ]).then(() =>
      this.setState({
        dataLoading: false
      })
      );
  }


  mountItem(){
    const { actions, actionType, userInfo } = this.props;
    const _this = this;
    if(actionType === "edit" && userInfo){
      return actions['get'+entityName+"ByUserId"](this.getProfileUserId()).then( response => {
        return _this.setState({
          item: objectPath.get(response, "data"),
        })
      });
    } else {
      return new Promise((resolve) => {
        
        _this.setState({
          item: null,
        }, resolve);
      });
    }
  }

  handleResponse = (response, values) => {
    const { enqueueSnackbar, actionType, history, actions } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      //console.log('handleResponse',response);
      var successMessage = TranslatorUtil.t("Profile")+" "+(actionType === "create" ? TranslatorUtil.t("added") : TranslatorUtil.t("updated"));
      enqueueSnackbar(successMessage, {
        variant: "success"
      });
      
      //related store changes
      actions.setTranslationMessagesByLang(objectPath.get(values,'langCode'));
      
      history.push(entityListUrl)
    }
  }

  getProfileUserId = () => {
    const { userInfo } = this.props;
    //to be able to edit another profile
    return objectPath.get(userInfo,'profile.uid');
  }

  handleSubmit = values => {
    const { actions, userInfo } = this.props;
      values.uid = this.getProfileUserId();
      if(AccessUtil.isRespondent(false)){
        this.notifyModeratorAndAdmins(values,objectPath.get(userInfo,'profile',null));
      }
    //has to return promise so submitting flag works!
    return actions.putProfile(values).then(response => this.handleResponse(response, values));
  };


  notifyModeratorAndAdmins = (newProfile,oldProfile) => {
    const { actions } = this.props;
    let propsToCheck = ['age','displayName','description','gender','phone'];
    let hasChange = false;
    propsToCheck.forEach(x => {
      if(!hasChange && objectPath.get(oldProfile,x,null) !== objectPath.get(newProfile,x,null)){
        hasChange = true;
      }
    });
    if(hasChange){
      let mailsToNotify = CompanyUtil.getCurrentCompanyAdminEmails().concat(AccessUtil.getModeratorsAccess());
      if(mailsToNotify.length){
        return Promise.all(
          mailsToNotify.map(to => {
            return actions.postNotification(EmailUtil.getRespondentProfileUpdated(
              CompanyUtil.getId(),
              newProfile,
              to
              ));
          })
        );
      }
      
    }
  }


  render() {
    const { actionType } = this.props;
    const { item, dataLoading } = this.state;
    

    return (
      <div>
        <PageHeading heading={(actionType === "create" ? TranslatorUtil.t("New") : TranslatorUtil.t("Update"))+" "+TranslatorUtil.t(FormatterUtil.camelToNormalForm(entityName))} actions={
          <div>
            <HeadingBackBtn redirectToUrl={entityListUrl} />
        </div>
        } />

      { dataLoading ? <ComponentLoading /> : 
      <ProfileForm 
        proceedSubmit={this.handleSubmit} 
        initialValues={item}

      /> }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userInfo
});

const mapDispatchToProps = dispatch =>
({
  changeFieldValue: function(field, value) {
    dispatch(change(entityFormName, field, value))
  },
  actions: bindActionCreators(
    {
      ...profileActions,
      ...translationActions,
      ...notificationActions
    },
    dispatch
  )
});


export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditProfile)
));