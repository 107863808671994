import objectPath from 'object-path';
import objectHash from 'object-hash';
import StoreUtil from '../StoreUtil';
import avatars from "../../constants/avatars";
import ArrayUtil from '../ArrayUtil';
import TranslatorUtil from '../TranslatorUtil';
import * as constants from '../../constants/constants';


export default class UsersUtil {


  static formatForUserSelector(){
    var users = StoreUtil.getValueByKey('users',[]);
    return users.map(x => {return {
      value: x.userId,
      label: x.firstName+" "+x.surname,
    }});
  }

  static getProfilesOptions(profiles){
    return profiles.map(x => ({
      value: x.email,
      label: (x.email+' ('+x.displayName+')')
    }))
  }

  static userExistsAndNotCurrent(userId){
    return userId && userId !== StoreUtil.getValueByKey('userInfo.firebase.uid',null)
  }

  static createDefaultProfileFromUser(user){
    return {
      uid: user.uid,
      email: user.email,
      displayName: UsersUtil.getDefaultDisplayName(user),
      avatar: UsersUtil.getRandomAvatar(),
      langCode: constants.DEFAULT_TRANSLATION_LANG
    }
  }

  static getUserProfileByUserId(id, profiles = []){
    profiles = profiles.length ? profiles : StoreUtil.getValueByKey('profiles',[]);
    return id ? profiles.find(x => x.uid===id) : null;
  }

  static getUserById(items,id){
    return id ? items.find(x => x.uid===id) : null;
  }

  static getNameFromStoreById(id, defaultValue = 'User not found'){
    var user = UsersUtil.getUserById(StoreUtil.getValueByKey('users',[]),id);
    return user ? objectPath.get(user,'firstName')+" "+objectPath.get(user,'surname') : defaultValue;
  }

  static getEmailFromStoreById(id, defaultValue = null){
    var user = UsersUtil.getUserById(StoreUtil.getValueByKey('users',[]),id);
    return objectPath.get(user,'email', defaultValue);
  }

  static getUserFromStoreById(id){
    return StoreUtil.getValueByKey('profiles',[]).find(x => x.uid===id);
  }

  static getUserEmailFromStoreById(id){
    var user = UsersUtil.getUserFromStoreById(id);
    return objectPath.get(user,'email', null);
  }

  static getDefaultDisplayName(user){
    var displayName = objectPath.get(user,'email', '');
    return displayName.indexOf('@') > 0 ? displayName.substring(0, displayName.indexOf("@")) : objectHash.MD5(user).substr(0,9);
  }

  static getUserDisplayName(user, anonymous = false){
    var displayName = objectPath.get(user,'displayName', TranslatorUtil.t('unknown'));
    return anonymous ? objectHash.MD5(displayName).substr(0,9) : displayName;
  }

  static getUserDisplayNameByUserInfo(userInfo, anonymous = false){
    return UsersUtil.getUserDisplayName(objectPath.get(userInfo,'profile'), anonymous);
  }

  static getUserDisplayNameById(userId, anonymous = false){
    return UsersUtil.getUserDisplayName(
      UsersUtil.getUserById(StoreUtil.getValueByKey('profiles',[]),userId)
    );
  }

  static getRandomAvatar(){
    return ArrayUtil.getRandItemIfExist(avatars.map(avatar => avatar.value));
  }

  static getAvatarUrlByAvatarId(avatarId){
    avatarId = avatarId ? avatarId : UsersUtil.getRandomAvatar();
    return "/assets/avatars/"+avatarId+".png";
  }

  static getAvatarUrlById(userId){
    var user = UsersUtil.getUserById(StoreUtil.getValueByKey('profiles',[]),userId);
    return UsersUtil.getAvatarUrlByAvatarId(objectPath.get(user,"avatar",null));
  }

  static getUserTagsByUserId(userId){
    var companyUserTags = StoreUtil.getValueByKey('userTags',[]);
    return objectPath.get(companyUserTags.find(x => x.id === userId),'tags',[]);
  }

  static getCurrentUserEmail(){
    return objectPath.get(
      StoreUtil.getValueByKey('userInfo',null),
      "firebase.email",
      null
      ); 
  }

  static getCurrentUserUid(){
    return objectPath.get(
      StoreUtil.getValueByKey('userInfo',null),
      "firebase.uid",
      null
      );
  }


}
