import * as types from '../constants/actionTypes';


export function getProjects(state = [], action) {
    if (action.type === types.GET_PROJECTS) {
        console.log(types.GET_PROJECTS+' set in store');
        return action.data;
    }
    return state;
}


export function getProject(state = null, action) {
    if (action.type === types.GET_PROJECT) {
        console.log(types.GET_PROJECT+' set in store');
        return action.data;
    } else if (action.type === types.GET_DISCUSSIONS) {
        console.log(types.GET_DISCUSSIONS+' set in store');
        var mergedStated = {
            ...state,
            discussions: action.data
        };
        return mergedStated;
    }
    return state;
}


