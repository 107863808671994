import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PageHeading from "../../../components/PageHeading";

import DiscussionForm from "../../../forms/discussion/form";
import * as discussionActions from "../../../actions/discussion";
import * as projectActions from "../../../actions/project";
import * as notificationActions from "../../../actions/notification";
import * as formNames from "../../../constants/forms";
import * as fileStorageActions from "../../../actions/fileStorage";
import objectPath from 'object-path';
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";

import HeadingBackBtn from "../../../components/buttons/HeadingBackBtn";
import SnackBarUtil from '../../../utils/SnackBarUtil';
import ComponentLoading from "../../../components/ComponentLoading";
import { change } from 'redux-form';
import TranslatorUtil from "../../../utils/TranslatorUtil";
import UrlBuilderUtil from "../../../utils/projectBased/UrlBuilderUtil";
import DataUtil from "../../../utils/DataUtil";

import { Box, Button } from "@material-ui/core";
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Alert } from "@material-ui/lab";
import AccessUtil from "../../../utils/projectBased/AccessUtil";
import EmailUtil from "../../../utils/projectBased/EmailUtil";
import CompanyUtil from "../../../utils/projectBased/CompanyUtil";

const entityName = "Discussion";
const entityFormName = formNames.DISCUSSION_FORM_NAME;


class EditDiscussion extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      item: null,
      project: null,
      dataLoading: true,
      uploadingImages: false
    }
  }

  componentDidMount(){
    Promise.all([
      this.mountProject(),
      this.mountItem()
    ]).then(() =>
      this.setState({
        dataLoading: false
      })
      );
  }

  mountProject(){
    const { actions, match } = this.props;
    const _this = this;
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");
    AccessUtil.isAtLeastModeratorForProject(projectId,true);
    return actions.getProject(projectId).then( response => {
      return _this.setState({
        project: objectPath.get(response, "data"),
      })
    });
  }

  mountItem(){
    const { actions, actionType, match } = this.props;
    const _this = this;
    var discussionId = UrlBuilderUtil.getRequestedParam(match, "discussionId");
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");
    if(actionType === "edit" && discussionId){
      return actions['get'+entityName](projectId,discussionId).then( response => {
        return _this.setState({
          item: objectPath.get(response, "data"),
        })
      });
    } else {
      return new Promise((resolve) => {
        
        _this.setState({
          item: null,
        }, resolve);
      });
    }
  }

  uploadImages = (values) => {
    var _this = this;
    var filteredNewImages = values && values.images && values.images.length ? values.images.filter(x => x.base64String && x.base64String.length) : [];
    if(filteredNewImages && filteredNewImages.length){
      _this.setState({uploadingImages:true});
      return Promise.all(filteredNewImages.map(x => _this.uploadImage(x))).then(() => _this.setState({uploadingImages:false}));
    } else {
      return Promise.resolve();
    }
  }

  uploadImage = (file) => {
    const { actions, enqueueSnackbar } = this.props;
    var _this = this;
    enqueueSnackbar(TranslatorUtil.t("Uploading")+" "+file.name, {
      variant: "info"
    });
    return actions.postFile(file).then(response => {
      console.log('uploadImages');
      
      return response.data.ref.getDownloadURL().then(function(downloadUrl) {
        file.downloadUrl = downloadUrl;
        return _this.handleResponse(response, file)
      });
    });
  }

  handleResponse = (response, values) => {
    const { enqueueSnackbar, actionType, actions, selectedProject, match } = this.props;
    var _this = this;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      var isFile = values.folder && values.folder.length;
      var successMessage = "\""+values.name+"\" "+ (isFile? TranslatorUtil.t("uploaded") : " "+(actionType === "create" ? TranslatorUtil.t("added") : TranslatorUtil.t("updated")));
      enqueueSnackbar(successMessage, {
        variant: "success"
      });
      if(!isFile){

        //send emails if created
        if(actionType === "create"){ 
          var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");
          var observers = AccessUtil.getObserversAccessByProject(projectId);
          var respondents = AccessUtil.getRespondentsAccessByProject(projectId);
          let mailsToNotify = observers.concat(respondents);
          Promise.all(mailsToNotify.map(mailToNotify => {
            return actions.postNotification(
              EmailUtil.getDiscussionAddedEmailObject(
                CompanyUtil.getId(),
                selectedProject,
                values,
                mailToNotify
                )
              );
          }))
          .then(() => {
              _this.finishAction();
            });
        } else {
          _this.finishAction();
        }
      }
    }
  }


  finishAction = () => {
    const { match, history } = this.props;
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");
    history.push(UrlBuilderUtil.getProjectDashboard(projectId))
  }


  handleSubmit = values => {
    const { actions, actionType, match } = this.props;
    let action = actions['post'+entityName];
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");
    if(actionType !== "create") {
      action = actions['put'+entityName];
      values.id = UrlBuilderUtil.getRequestedParam(match, "discussionId");
    }
    this.uploadImages(values).then(() => {
      return action(projectId,DataUtil.removeImagesBase64(values,'images'))
        .then(response => {
          this.handleResponse(response, values);
        });
    });
    //has to return promise so submitting flag works!
    
  };


  toggleLock = () => {
    const { actions, match, enqueueSnackbar } = this.props;
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");
    var discussionId = UrlBuilderUtil.getRequestedParam(match, "discussionId");
    var isLocked = this.isLocked();
    return actions[(isLocked ? 'unlock' : 'lock')+entityName](projectId,discussionId).then(() => {
      Promise.all([
        this.mountProject(),
        this.mountItem()
      ]).then(() =>enqueueSnackbar(TranslatorUtil.t(entityName) +" "+(isLocked ? TranslatorUtil.t("unlocked") : TranslatorUtil.t("locked")), {
        variant: "info"
      }));
    });
    
  }

  isLocked = () => {
    const { item } = this.state;
    return AccessUtil.isDiscussionLocked(item);
  }

  isLockedOnProjectLevel = () => {
    const { project } = this.state;
    return AccessUtil.isProjectLocked(project);
  }


  render() {
    const { actionType, match } = this.props;
    const { item, dataLoading, uploadingImages } = this.state;
    
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");

    return (
      <div>
        <PageHeading heading={TranslatorUtil.t((actionType === "create" ? "New" : "Update")+" Discussion")} actions={
          <div>
            <HeadingBackBtn redirectToUrl={UrlBuilderUtil.getProjectDashboard(projectId)} />
        </div>
        } />

      { dataLoading ? <ComponentLoading /> : <React.Fragment>
      <DiscussionForm 
        proceedSubmit={this.handleSubmit} 
        initialValues={item}
        isLocked={this.isLocked()}
        submittingImages={uploadingImages}
      /> 
      
      {
        actionType !== "create" ? <Box pt={4} className="narrowMaxMediumWidthPart">
      <Box py={2}>
        {
          this.isLockedOnProjectLevel() ? <Alert severity="error">{TranslatorUtil.t("Locked on project level!")}</Alert> : <React.Fragment>
            <Alert severity={"warning"}>
    {this.isLocked() ? TranslatorUtil.t("Unlock") : TranslatorUtil.t("Lock")} {TranslatorUtil.t("discussion for all respondents manually")}
    </Alert>
    <Box py={2}>
    <Button variant="contained" startIcon={this.isLocked() ? <LockOpenOutlinedIcon /> : <LockOutlinedIcon color="error" />} onClick={this.toggleLock}>{this.isLocked() ? TranslatorUtil.t("Unlock") : TranslatorUtil.t("Lock")  } {TranslatorUtil.t("manually")}</Button>
    </Box></React.Fragment>
        }
    
    </Box>
    
    </Box> : null
    }

    </React.Fragment>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedProject: state.selectedProject
});

const mapDispatchToProps = dispatch =>
({
  changeFieldValue: function(field, value) {
    dispatch(change(entityFormName, field, value))
  },
  actions: bindActionCreators(
    {
      ...discussionActions,
      ...projectActions,
      ...fileStorageActions,
      ...notificationActions,
    },
    dispatch
  )
});


export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditDiscussion)
));