
export const REPORTING_ITEM_FORM_NAME = "GeneralItemForm";
export const REPORTING_ITEM_QUICK_COMMENT_FORM_NAME = "GeneralItemFormQuickComment";
export const SIMPLE_ITEM_FORM_NAME = "SimpleItemForm";
export const PROJECT_FORM_NAME = "ProjectForm";
export const PROFILE_FORM_NAME = "ProfileForm";
export const USER_COMMENT_FORM_NAME = "UserCommentForm";
export const USER_TAGS_FORM_NAME = "UserTagsForm";
export const DISCUSSION_FORM_NAME = "DiscussionForm";
export const DISCUSSION_CONVERSATION_FORM_NAME = "DiscussionConversationForm";
export const DISCUSSION_USERS_FORM_NAME = "DiscussionUsersForm";
export const PROJECT_USERS_FORM_NAME = "ProjectUsersForm";
export const CHAT_CONVERSATION_FORM_NAME = "ChatConversationForm";
export const LOGIN_FORM_NAME = "LoginForm";
export const REGISTRATION_FORM_NAME = "RegistrationForm";
export const RESET_PASSWORD_FORM_NAME = "ResetPassword";
export const NOTIFICATION_FORM_NAME = "NotificationForm";


