import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import firestoreApi from '../firebase.js';
import FirestoreCollectionUtil from "../utils/FirestoreCollectionUtil";
import * as collections from '../constants/firebaseCollections';
import CompanyUtil from "../utils/projectBased/CompanyUtil";
import DateUtil from "../utils/DateUtil";

const collectionName = collections.USER_COMMENTS;


export function getUserCommentsByUserId(userId) {
  return function(dispatch) {
    console.log('getUserCommentsByUserId called', userId);
    return firestoreApi
    .collection(CompanyUtil.getCollectionPathWithCompanyPrefix(collectionName))
    .where("userId", "==", userId)
    .get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_USER_COMMENTS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_USER_COMMENTS))
      );
  };
}


export function listenUserCommentsByUserId(userId) {
  return function(dispatch) {
    console.log('getUserCommentsByUserId called', userId);

    
    return firestoreApi
    .collection(CompanyUtil.getCollectionPathWithCompanyPrefix(collectionName))
    .where("userId", "==", userId)
    // .where("archived", "==", true)
    //   .where("deleted", "==", false)
    //   .orderBy("displayName")
    .onSnapshot()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_USER_COMMENTS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_USER_COMMENTS))
      );
  };
}

  export function postUserComment(data) {
    return function(dispatch) {
      console.log('postUserComment');
      data.createdTime = DateUtil.timestamp();
      return firestoreApi
      .collection(CompanyUtil.getCollectionPathWithCompanyPrefix(collectionName))
      .add(data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_USER_COMMENT)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_USER_COMMENT))
        );
    };
  }






 export function deleteUserComment(id) {
    return function(dispatch) {
      console.log('deleteUserComment');
      return firestoreApi
      .collection(CompanyUtil.getCollectionPathWithCompanyPrefix(collectionName))
      .doc(id)
      .delete()
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.DELETE_USER_COMMENT)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.DELETE_USER_COMMENT))
        );
    };
}