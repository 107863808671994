import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import firestoreApi from '../firebase.js';
import FirestoreCollectionUtil from "../utils/FirestoreCollectionUtil";
import * as collections from '../constants/firebaseCollections';
import CompanyUtil from "../utils/projectBased/CompanyUtil";
import DateUtil from "../utils/DateUtil";


export function listenChatConversations(userId) {
  return function(dispatch) {
    console.log('listenChatConversations called '
    //,userId
    );
    return firestoreApi.collection(collections.CHAT)
    .where("userIds", "array-contains", userId)
    .onSnapshot(response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_CHAT_CONVERSATIONS)))
  };
}

export function listenChatConversationMessages(chatId) {
  return function(dispatch) {
    console.log('listenChatConversationMessages called '
    //,chatId
    );
    return firestoreApi
    .collection(
        [collections.CHAT,chatId,collections.MESSAGES].join('/')
    )
      .onSnapshot(response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_CHAT_CONVERSATION_MESSAGES)));
  };
}


export function postChatConversationMessage(chatId,data) {
  return function(dispatch) {
    console.log('postChatConversationMessage'
    //,[collections.CHAT,chatId,collections.MESSAGES].join('/')
    );
    data.createdTime = DateUtil.timestamp();
    data.updatedTime = DateUtil.timestamp();
    return firestoreApi.collection(
        [collections.CHAT,chatId,collections.MESSAGES].join('/')
    ).add(data)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_CHAT_CONVERSATION_MESSAGE)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_CHAT_CONVERSATION_MESSAGE))
      );
  };
}

export function postChatConversation(data) {
  return function(dispatch) {
    console.log('postChatConversation '
    //,data
    );
    data.createdTime = DateUtil.timestamp();
    data.updatedTime = DateUtil.timestamp();
    return firestoreApi.collection(
        collections.CHAT
    ).add(data)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_CHAT_CONVERSATION)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_CHAT_CONVERSATION))
      );
  };
}

export function getDiscussionMessage(projectId,discussionId,messageId) {
  return function(dispatch) {
    console.log('getDiscussion');
    return firestoreApi.collection(
      CompanyUtil.getCollectionPathWithCompanyPrefix(collections.PROJECTS, [projectId,collections.DISCUSSIONS,discussionId,collections.MESSAGES])
    ).doc(messageId).get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionItemData(response), types.GET_DISCUSSION_MESSAGE)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_DISCUSSION_MESSAGE))
      );
  };
}