import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import * as constants from '../../constants/constants';
import { CardHeader, Avatar, CardContent, CardActions, IconButton, Tooltip, Typography, Badge } from '@material-ui/core';
import FormatterUtil from '../../utils/FormatterUtil';
import TranslatorUtil from '../../utils/TranslatorUtil';
import { withRouter } from "react-router-dom";
import DateUtil from '../../utils/DateUtil';
import objectPath from 'object-path';
import DraftsIcon from '@material-ui/icons/Drafts';
import classnames from 'classnames';
import DoneIcon from '@material-ui/icons/Done';
import IconUtil from '../../utils/projectBased/IconsUtil';

class NotificationCardContent extends React.Component {



  redirectToItem = () => {
    const { item, history } = this.props;  
    var url = objectPath.get(item,'actionURL',null);
      if(url){
        url = FormatterUtil.removeAbsolutePathFromURL(url);
        history.push(url);
      }
  }

  cardClicked = () => {
    const { item, onMarkAsReadClicked } = this.props;  
    if(item.read){
      return this.redirectToItem();
    } else {
      return onMarkAsReadClicked().then(() => this.redirectToItem());
    }
    
  }

  render () {
  const { classes, item, onMarkAsReadClicked } = this.props;

  return (
    <div className={"notification "+(item.read ? "read" : "unread")}>
    <CardHeader
      onClick={() => this.cardClicked()}
        avatar={
          

            item.read ? <Badge className="badge" badgeContent={<DoneIcon size="small"/>}>
            <Avatar aria-label={item.name} className={classnames(classes.avatar,"avatar")}>
            {IconUtil.getNotificationIcon(item.type)}
              </Avatar>
</Badge>: <Avatar aria-label={item.name} className={classnames(classes.avatar,"avatar")}>
  {IconUtil.getNotificationIcon(item.type)}
          </Avatar>
        }
        titleTypographyProps={{variant:'h5' }}
        title={item.message.subject}
        subheader={DateUtil.displayTimeFromNow(item.createdTime,TranslatorUtil.getUserLangFromStore())}
      />
      <CardContent
      onClick={() => this.cardClicked()}
      >
        <Typography variant="body2" color="textSecondary" component="small">
        {item.message.text}
        </Typography>
      </CardContent>

{!item.read ? <CardActions className="actions">

      <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Mark as read")}>
                                    <IconButton size="small"  aria-label={TranslatorUtil.t("Mark as read")} onClick={() => onMarkAsReadClicked()}>
                                        <DraftsIcon />
                                    </IconButton>
                                </Tooltip>
                                
    </CardActions>: null }
    </div>
  );
    }
}


export default withRouter(withStyles(styles, { withTheme: true })(NotificationCardContent));