import React from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PageHeading from "../../../components/PageHeading";
import * as projectActions from '../../../actions/project';
import HeadingAddBtn from "../../../components/buttons/HeadingAddBtn";
import ComponentLoading from "../../../components/ComponentLoading";
import FormatterUtil from "../../../utils/FormatterUtil";
import { withSnackbar } from "notistack";
import SnackBarUtil from "../../../utils/SnackBarUtil";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import UrlBuilderUtil from "../../../utils/projectBased/UrlBuilderUtil";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Masonry from 'react-masonry-component';
import ArrayUtil from "../../../utils/ArrayUtil";
import { Alert } from '@material-ui/lab';
import ProjectCardContent from "../../../components/project/ProjectCardContent";
import { Card } from "@material-ui/core";
import AccessUtil from "../../../utils/projectBased/AccessUtil";
import HeadingGeneralBtn from "../../../components/buttons/HeadingGeneralBtn";
import ArchiveIcon from '@material-ui/icons/Archive';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

const entityName = "Project";
const entityListUrl = "/projects/";

class ListProject extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      dataLoading: true,
      itemToDelete: null,
    }
  }
  
  renderConfirmationDialog = () => {
    const { itemToDelete } = this.state;
    if(itemToDelete){
      return (<ConfirmDialog 
      message={TranslatorUtil.t('Please confirm you wish to remove ')+FormatterUtil.camelToNormalForm(entityName)+' '+itemToDelete.name}
      onClose={() => this.onDeleteClicked(null)}
      onConfirm={() => this.handleDelete(itemToDelete.id)}/>)
    }
  }

  onDeleteClicked = (item) => {
    this.setState({
      itemToDelete: item
    });
  }

  handleDelete = (id) => {
    const { actions } = this.props;
    return actions.deleteProject(id).then(response => this.handleResponse(response));
  }

  

  handleResponse = response => {
    
    const { enqueueSnackbar } = this.props;
    this.onDeleteClicked(null);//hide confirm
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      SnackBarUtil.showSuccessMessage(response, enqueueSnackbar);
        //reload
        this.mountItems(true);
      
      
    }
  }

  onSelectClicked = (item) => {
    const { history } = this.props;
    return history.push(UrlBuilderUtil.getProjectDashboard(item.id));
  }

  mountItems = (hardReload = false) => {
    const _this = this;
    const { actions } = this.props;

      
      actions.getProjects().then( response => {
        return _this.setState({
          dataLoading: false
        })
      });
     
  }
  


  componentDidMount(){

    
    this.mountItems()
  }


  render () {
    const { dataLoading, itemToDelete } = this.state;
    const { items, isArchive } = this.props;

    const masonryOptions = {
      itemSelector: '.masonry-item',
      columnWidth: '.masonry-item-sizer',
      percentPosition: true,
      horizontalOrder: true
    };

    return <div>
    <PageHeading heading={(isArchive ? (TranslatorUtil.t('Archived') + " ") : "")+TranslatorUtil.t(FormatterUtil.camelToNormalForm(entityName)+"s")} 
    actions={AccessUtil.isCompanyAdmin() ? 
    <React.Fragment>
      { !isArchive ? <HeadingGeneralBtn icon={<ArchiveIcon />}  redirectToUrl={entityListUrl+'archive/'} title={TranslatorUtil.t('Archived projects')} /> : <HeadingGeneralBtn icon={<BusinessCenterIcon />}  redirectToUrl={entityListUrl} title={TranslatorUtil.t('Active projects')} />} 
      { !isArchive ? <HeadingAddBtn redirectToUrl={entityListUrl+'create/'} /> : null }
      </React.Fragment> 
      : null } />
    { dataLoading ? <ComponentLoading /> : items && items.length ?
      <Masonry
      //ref={function(c) {this.masonry = this.masonry || c.masonry;}.bind(this)}
      className={'masonry-grid-wrap'} // default ''
      options={masonryOptions} // default {}
    >
      <div className="masonry-item-sizer"></div>
      {ArrayUtil.sortByDateCreated(items, false).filter(x => ((isArchive && AccessUtil.isProjectLocked(x)) || (!isArchive && !AccessUtil.isProjectLocked(x))) && AccessUtil.isAtLeastRespondentForProject(x.id)).map((item, index) => {

          return <div className="masonry-item" key={index}>
            
            <Card elevation={4}>
              <ProjectCardContent
                item={item} 
                onDeleteClicked={AccessUtil.canEditProject(item.id) ? this.onDeleteClicked : null}
                onSelectClicked={this.onSelectClicked}
              />
            </Card>
          </div>;
      })}
    </Masonry> : <Alert severity="info">{TranslatorUtil.t('No results')}</Alert> }
      { itemToDelete ? this.renderConfirmationDialog() : null}
    
  </div>
  }
}


function mapStateToProps(state) {
  return {
    items: state.projects,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...projectActions,
      },
      dispatch
    )
  };
}


export default withRouter(withSnackbar(connect(
  mapStateToProps,
  mapDispatchToProps
)(ListProject)));
