import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import userReducer from './user';
import menuReducer from './menu';
import translationsReducer from './translations';
import * as profilesReducer from './profiles';
import * as chatReducer from './chat.js';
import * as infoTextReducer from './infoText';
import * as projectsReducer from './projects';
import * as messagesReducer from './messages';
import * as drawerReducer from './drawer';
import * as uiReducer from './ui';
import * as routerReducer from './router';
import * as accessReducer from './access';
import * as errorReducer from './error';
import * as userTagsReducer from './userTags';
import * as notificationsReducer from './notifications';

const rootReducer = combineReducers({
    
    form: formReducer,
    userInfo: userReducer,
    menu: menuReducer,
    translations: translationsReducer,
    drawerOpen: drawerReducer.showHideDrawer,
    contentSubmenuLevel: uiReducer.setContentSubmenuLevel,
    chatConversations: chatReducer.getChatConversations,
    selectedChatConversationMessages: chatReducer.getChatConversationMessages,
    projects: projectsReducer.getProjects,
    profiles: profilesReducer.getProfiles,
    access: accessReducer.getAccess,
    companyAccess: accessReducer.getCompanyAccess,
    userTags: userTagsReducer.getUserTags,
    selectedProject: projectsReducer.getProject,
    messages: messagesReducer.getMessages,
    discussionLikes: messagesReducer.getDiscussionLikes,
    infoText: infoTextReducer.getInfoText,
    routerLocations: routerReducer.getRouterLocations,
    requestErrors: errorReducer.getRequestErrors,
    lastRequestError: errorReducer.getLastRequestError,
    notifications: notificationsReducer.getNotifications
});

export default rootReducer;