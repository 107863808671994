import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import firestoreApi from '../firebase.js';
import FirestoreCollectionUtil from "../utils/FirestoreCollectionUtil";
import DateUtil from "../utils/DateUtil";

const collectionName = 'profiles';


export function getProfiles() {
  return function(dispatch) {
    console.log('getProfiles called');
    return firestoreApi.collection(collectionName).get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_PROFILES)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_PROFILES))
      );
  };
}

export function listenProfiles() {
  return function(dispatch) {
    console.log('listenProfiles called');
    return firestoreApi.collection(collectionName)
    .onSnapshot(
      response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response),  types.GET_PROFILES)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_PROFILES))
      );
  };
}


  export function postProfile(data) {
    return function(dispatch) {
      console.log('postProfile');
      data.createdTime = DateUtil.timestamp();
      data.updatedTime = DateUtil.timestamp();
      return firestoreApi.collection(collectionName).add(data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_PROFILE)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_PROFILE))
        );
    };
  }

  export function putProfile(data) {
    return function(dispatch) {
      console.log('putProfile');
      data.updatedTime = DateUtil.timestamp();
      return firestoreApi.collection(collectionName).doc(data.id).set(FirestoreCollectionUtil.removeMetaData(data))
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(data, types.PUT_PROFILE)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.PUT_PROFILE))
        );
    };
  }

  export function getProfile(id) {
    return function(dispatch) {
      console.log('getProfile');
      return firestoreApi.collection(collectionName).doc(id).get()
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionItemData(response), types.GET_PROFILE)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_PROFILE))
        );
    };
  }

  export function postFirestoreDefaultProfile(data) {
    console.log('postFirestoreDefaultProfile');
    data.createdTime = DateUtil.timestamp();
    return firestoreApi.collection(collectionName).add(data);
}

  export function getFirestoreProfileByUserId(id) {
    console.log('getFirestoreProfileByUserId', id);
    return firestoreApi.collection(collectionName).where("uid", "==", id).limit(1).get()
      .then(
        response => 
           FirestoreCollectionUtil.parseFirstItemFromCollectionData(response)
      );
}

  export function getProfileByUserId(id) {
    return function(dispatch) {
      console.log('getProfileByUserId');
      return firestoreApi.collection(collectionName).where("uid", "==", id).limit(1).get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseFirstItemFromCollectionData(response), types.GET_PROFILE)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_PROFILE))
      );
    };
  }
