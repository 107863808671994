import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import * as constants from '../../constants/constants';
import { CardHeader, Avatar, CardContent, Typography, CardActions, IconButton, Tooltip } from '@material-ui/core';
import FormatterUtil from '../../utils/FormatterUtil';
import UrlBuilderUtil from '../../utils/projectBased/UrlBuilderUtil';
import TranslatorUtil from '../../utils/TranslatorUtil';
import AccessUtil from '../../utils/projectBased/AccessUtil';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { withRouter } from "react-router-dom";
import DateUtil from '../../utils/DateUtil';
import ForumIcon from '@material-ui/icons/Forum';
import RichTextRenderer from '../RichTextRenderer';
import LockIcon from '@material-ui/icons/Lock';
//import GroupIcon from '@material-ui/icons/Group';

class DiscussionCardContent extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        expanded: false,
      }
    }

  handleExpandClick = () => {
    this.setState({
      expanded: !this.state.expanded
    })
  };

  render () {
  const { classes, project, index, item, history, onDeleteClick } = this.props;

  return (
    <div>
    <CardHeader
      onClick={() => history.push(UrlBuilderUtil.getDiscussionConversation(project.id, item.id))}
        avatar={
          <Avatar aria-label={item.name} className={classes.avatar} style={{backgroundColor:item.color}}>
            {index ? index : FormatterUtil.getIconText(item.name)}
          </Avatar>
        }
        titleTypographyProps={{variant:'h2' }}
        title={item.name}
        subheader={DateUtil.displayTimeFromNow(item.createdTime, TranslatorUtil.getUserLangFromStore())}
      />
      <CardContent
      onClick={() => history.push(UrlBuilderUtil.getDiscussionConversation(project.id, item.id))}
      >
        <Typography variant="body2" color="textSecondary" component="div">
          <RichTextRenderer text={item.description} />
        </Typography>
      </CardContent>
      <CardActions>

      { AccessUtil.isProjectLocked(project) || AccessUtil.isDiscussionLocked(item) ?
        <Avatar className={classes.avatar}>
        <LockIcon />
      </Avatar> : null
        }

      <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Start discussion")}>
          <IconButton aria-label={TranslatorUtil.t("Start discussion")} onClick={() => history.push(UrlBuilderUtil.getDiscussionConversation(project.id, item.id))}>
              <ForumIcon />
          </IconButton>
      </Tooltip>
      {
        AccessUtil.canEditProject(project.id) ? <React.Fragment>
<Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Edit")}>
          <IconButton aria-label={TranslatorUtil.t("Edit")} onClick={() => history.push(UrlBuilderUtil.getDiscussionEdit(project.id, item.id))}>
              <EditIcon />
          </IconButton>
      </Tooltip>

      {onDeleteClick ? <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Delete")}>
                                  <IconButton  aria-label={TranslatorUtil.t("Delete")} onClick={() => onDeleteClick(item)}>
                                      <DeleteIcon color="error" />
                                  </IconButton>
                              </Tooltip> : null}
        </React.Fragment> : null
      }
      
    </CardActions>
    </div>
  );
    }
}


export default withRouter(withStyles(styles, { withTheme: true })(DiscussionCardContent));