import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import firestoreApi from '../firebase.js';
import FirestoreCollectionUtil from "../utils/FirestoreCollectionUtil";
import * as collections from '../constants/firebaseCollections';
import NotificationUtil from "../utils/projectBased/NotificationUtil";
import DateUtil from "../utils/DateUtil";

const collectionName = collections.NOTIFICATION;

export function postNotification(data) {
    return function(dispatch) {
        console.log('postNotification');
        data.createdTime = DateUtil.timestamp();

        return firestoreApi.collection(
          collectionName
            ).add(NotificationUtil.addMetaDataToNew(data))
    .then(
      response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_NOTIFICATION)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_NOTIFICATION))
    );
    };
}

export function putNotification(id, data) {
  return function(dispatch) {
      console.log('putNotification');
      data.updatedTime = DateUtil.timestamp();
      return firestoreApi.collection(
        collectionName
          )
          .doc(id)
    .set(NotificationUtil.addMetaDataToNew(data))
  .then(
    response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_NOTIFICATION)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_NOTIFICATION))
  );
  };
}

export function markNotificationAsRead(id) {
  return function(dispatch) {
      console.log('markNotificationAsRead');
      return firestoreApi.collection(
        collectionName
          )
          .doc(id)
    .update({read : true})
  .then(
    response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_NOTIFICATION)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_NOTIFICATION))
  );
  };
}

export function getNotificationsByEmail(userEmail,read = false) {
  return function(dispatch) {
    console.log('getNotificationsByEmail');
    return firestoreApi.collection(
      collectionName
        )
        .where("to", "==", userEmail)
        .where("to", "==", userEmail)
      .limit(30)
        .orderBy("createdTime", "desc")
        .get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionItemData(response), types.GET_NOTIFICATIONS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_NOTIFICATIONS))
      );
  };
}


export function listenNotificationsByEmail(userEmail) {
    return function(dispatch) {
      console.log('listenNotificationsByEmail called ',userEmail);
      return firestoreApi.collection(collectionName)
      .where("to", "==", userEmail)
      .limit(30)
      .orderBy("createdTime", "desc")
      .onSnapshot(response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_NOTIFICATIONS)))
    };
  }