import React from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as invitationActions from "../../../actions/invitation";
import * as accessActions from "../../../actions/access";
import * as notificationActions from "../../../actions/notification";
import * as constants from '../../../constants/constants';
import ComponentLoading from "../../../components/ComponentLoading";
import { withStyles } from '@material-ui/core/styles';
import styles from "../../../theme/styles";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import FormatterUtil from "../../../utils/FormatterUtil";
import objectPath from "object-path";
import { Alert, AlertTitle } from "@material-ui/lab";
import DateUtil from "../../../utils/DateUtil";
import InvitationContent from "../../../components/invitation/InvitationContent";
import LocalStorageUtil from "../../../utils/LocalStorageUtil";
import classnames from 'classnames';
import UrlBuilderUtil from "../../../utils/projectBased/UrlBuilderUtil";
import ArrayUtil from "../../../utils/ArrayUtil";
import { Button } from "@material-ui/core";
import EmailUtil from "../../../utils/projectBased/EmailUtil";
import AccessUtil from "../../../utils/projectBased/AccessUtil";

class Invitation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        invitation: null,
        company: null,
        project: null,
        discussion: null,
        dataLoading: true,
        userAccess: null
    }
  }


  getInvitation = () => {
      const { match } = this.props;
      var invitation = null;
      var queryHash = objectPath.get(match,'params.invitationHash',null);
      if(LocalStorageUtil.get('invitationCompanyId') && LocalStorageUtil.get('invitationProjectId')){
        invitation = {
          companyId: LocalStorageUtil.get('invitationCompanyId'),
          projectId: LocalStorageUtil.get('invitationProjectId')
        };
      } else if(queryHash !== 'active'){
          var queryInvitation = FormatterUtil.parseJsonSafe(FormatterUtil.decodeString(queryHash));
          if(this.isValid(queryInvitation, false)){
            LocalStorageUtil.set('invitationCompanyId',queryInvitation.companyId);
            LocalStorageUtil.set('invitationProjectId',queryInvitation.projectId);
            invitation = queryInvitation;
          }
      }

      if(this.isValid(invitation, false)){
        this.setState({
          invitation: invitation
            },() => {
                this.getInvitationRelatedData(invitation);
        });
      } else {
        this.setState({dataLoading: false})
      }
           
  }

  getProject = (invitation) => {
      const { actions } = this.props;
    return actions.getInvitationProject(invitation).then((response) => this.setState({project: objectPath.get(response,'data')}));
    }

    getCompany = (invitation) => {
        const { actions } = this.props;
        return actions.getInvitationCompany(invitation).then((response) => this.setState({company: objectPath.get(response,'data')}));
        }

        getInvitationAccess = (invitation) => {
            const { actions } = this.props;
            return actions.getCompanyProjectAccessItems(
                invitation.companyId,
                invitation.projectId
                ).then((response) => this.setState({projectAccess: objectPath.get(response,'data')}));
            }

  getInvitationRelatedData = (invitation) => {
      var _this = this;
      const { userInfo } = this.props;
    if(userInfo){
      Promise.all([
        this.getInvitationAccess(invitation),
        this.getCompany(invitation), 
        this.getProject(invitation)
      ]).then(()=>_this.setState({dataLoading: false}));
    }
    
    }

  componentDidMount() {
      this.getInvitation();
  }

  componentWillUnmount(){
      
  }


  isValid = (invitation, checkDate) => {
    if(invitation && invitation.companyId && invitation.projectId && (
      (checkDate && invitation.expire >= DateUtil.getTimezoneTime().valueOf()) || !checkDate)
      ){
        return true;
    }
    return false;
}

getUserEmail = () => {
  const { userInfo } = this.props;
  return objectPath.get(userInfo,'firebase.email',null);
}

acceptInvitation = () => {
    const { userAccess, company, project, invitation, projectAccess } = this.state;
    const { history, actions } = this.props;


    const companyAdminEmails = objectPath.get(company,'adminEmails',[]);
    var projectRolesAccess = {};
    var allRolesEmails = [];
    //console.log('projectAccess',projectAccess);
    if(projectAccess){
      projectAccess.forEach(roleAccess => {
        if(roleAccess.roleType){
          projectRolesAccess[roleAccess.roleType] = roleAccess.userEmails;
          allRolesEmails = allRolesEmails.concat(roleAccess.userEmails);
        }
      });
    }
    const userInfoEmail = this.getUserEmail();
    

    if(companyAdminEmails.indexOf(userInfoEmail) > -1 
      || allRolesEmails.indexOf(userInfoEmail) > -1
      ){ // has access redirect to project
        LocalStorageUtil.remove('invitationCompanyId');
        LocalStorageUtil.remove('invitationProjectId');
        
        history.push(UrlBuilderUtil.getProjectDashboard(project.id));
    } else { // does not exist add as respondent
        var existedRespondentsAccess = Array.isArray(projectAccess) && projectAccess.find(x => x.roleType === constants.RESPONDENTS);
        var action = existedRespondentsAccess ? "putUserAccess" : "postUserAccess";
        var newUserAccess = userAccess;
        if(action === "postUserAccess"){ // add new access object 
          newUserAccess = {
            companyId: invitation.companyId,
            projectId: invitation.projectId,
            discussionId: "all",
            roleType: constants.RESPONDENTS,
            userEmails: [userInfoEmail]
          }
        } else { // add user email to access object 
          existedRespondentsAccess.userEmails = ArrayUtil.isNonEmptyArray(existedRespondentsAccess.userEmails) ? existedRespondentsAccess.userEmails.concat([userInfoEmail]) : [userInfoEmail];
          newUserAccess = existedRespondentsAccess;
        }
        console.log('action '+action,newUserAccess);
        actions[action](newUserAccess).then(() => {
          let mailsToNotify = companyAdminEmails.concat(objectPath.get(projectRolesAccess,constants.MODERATORS,[]));
          Promise.all(mailsToNotify.map(mailToNotify => actions.postNotification(
            EmailUtil.getInvitationAcceptedEmailObject(
              invitation.companyId,
              project,
              mailToNotify,
              userInfoEmail,
              constants.RESPONDENTS
              )
            )))
          .then(() => {
            LocalStorageUtil.remove('invitationCompanyId');
            LocalStorageUtil.remove('invitationProjectId');
            
            //redirect to project
            window.location.href = UrlBuilderUtil.getRquestedHostUrl() + UrlBuilderUtil.getProjectDashboard(project.id);
          });
          
        });
    }
}

goToAuth = () => {
  const { history } = this.props;
  history.push(UrlBuilderUtil.getRegistration())
}

goHome = (cancelInvitation) => {
  if(cancelInvitation){
    LocalStorageUtil.remove('invitationCompanyId');
    LocalStorageUtil.remove('invitationProjectId');
  }
  const { history } = this.props;
  history.push(UrlBuilderUtil.getDashboard());
}

goToProject = () => {
    LocalStorageUtil.remove('invitationCompanyId');
    LocalStorageUtil.remove('invitationProjectId');
  const { history } = this.props;
  const { project } = this.state;
  history.push(UrlBuilderUtil.getProjectDashboard(objectPath.get(project,'id',null)));
}

  render() {
    const { dataLoading, invitation, company, project } = this.state;
    const { classes } = this.props;

    var hasError = !(invitation && objectPath.get(project,'byEmailInvitationOnly',false) === false);

    return <div>
        <div className={classnames(classes.contentWithoutHeader,"narrowMaxMediumWidthPart")}>
            {dataLoading ? <ComponentLoading /> : (!hasError ? <InvitationContent 
                acceptInvitation={this.getUserEmail() ? (!AccessUtil.hasAccessToProject(
                  objectPath.get(company,'id',null),
                  objectPath.get(project,'id',null),
                  this.getUserEmail()
                  ) ? this.acceptInvitation : this.goToProject()) : null}
                goToAuth={this.getUserEmail() ? null : this.goToAuth}
                company={company}
                project={project}
                invitation={invitation} /> : <div><Alert severity="error">
                <AlertTitle>{TranslatorUtil.t("Error")}</AlertTitle>
                      {TranslatorUtil.t("Unfortunately something went wrong and invitation has expired or is not valid anymore")}
                          
                    </Alert>
                    <br />
                    <Button variant="contained" className="linkBtn" color={"primary"} onClick={() => this.goHome(true)}>{TranslatorUtil.t('Continue here')}</Button>
                    </div>)
        
        }
          </div>
    </div>
  }
}


function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...invitationActions,
        ...accessActions,
        ...notificationActions
      },
      dispatch
    )
  };
}


export default withRouter(withStyles(styles, { withTheme: true })(connect(
  mapStateToProps,
  mapDispatchToProps
)(Invitation)));
