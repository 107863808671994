import React from 'react'
import AppCard from '../../components/AppCard';
import { Typography, Button } from '@material-ui/core';
import TranslatorUtil from '../../utils/TranslatorUtil';

const ServerError = ({ location }) => (
  <div>
    <AppCard type="error">
          <Typography variant="h1">{TranslatorUtil.t("Internal Server Error")}</Typography>
          <br />
          <Typography variant="body1">
          {TranslatorUtil.t("Sorry, something went wrong Please try again later")}
          </Typography>
          <br />
          <br />
          <Button href="/" variant="contained" color="default">{TranslatorUtil.t("Home page")}</Button>
    </AppCard>
    
  </div>
)

export default ServerError
