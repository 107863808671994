import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";  
import styles from '../../theme/styles';
import UserCommentForm from '../../forms/userComment/form';
import UserTagsForm from '../../forms/userTags/form';
import { Typography, DialogContent, Dialog, AppBar, Toolbar, IconButton } from '@material-ui/core';
import ComponentLoading from '../ComponentLoading';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as userCommentsActions from '../../actions/userComments';
import * as userTagsActions from '../../actions/userTags';
import TranslatorUtil from '../../utils/TranslatorUtil';
import CloseIcon from '@material-ui/icons/Close';
import { withSnackbar } from "notistack";
import Slide from '@material-ui/core/Slide';
import UserInfo from './UserInfo';
import CommentsList from '../comments/CommentsList';
import objectPath from 'object-path';
import AccessUtil from '../../utils/projectBased/AccessUtil';
import CompanyUtil from '../../utils/projectBased/CompanyUtil';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class UserDialog extends React.Component {


      constructor(props){
        super(props);
        this.state={
          comments: [],
          dataLoading: true,
          allUserTags: [],
          currentUserTags: [],
        }
      }


      componentDidMount(){
          Promise.all([
            this.mountComments(),
            this.mountTags()
          ]).then(() =>
            this.setState({
              dataLoading: false
            })
            );
      }

      mountComments = () => {
        const { actions, userId } = this.props;
        return actions.getUserCommentsByUserId(userId).then((response) => 
          this.setState({comments: objectPath.get(response, "data",[])}));
      }


      mountTags(){
        const { actions, userId } = this.props;
        return actions.getUserTagsByUserId(userId).then((response) => {
          this.setState({
            currentUserTags: objectPath.get(response, "data",[]),
            allUserTags : CompanyUtil.getCompanyUserTags()
          })
        });
      }

      handleSubmitComment = (data) => {
        const { actions, userId, enqueueSnackbar, userInfo } = this.props;
        data.userId = userId;
        data.byUserId = objectPath.get(userInfo, "firebase.uid");
        return actions.postUserComment(data).then(() => {
          this.mountComments();
          enqueueSnackbar(TranslatorUtil.t("Comment added."), { variant: "success"});
        });
      }

      handleSubmitTags = (data) => {
        const { actions, userId, enqueueSnackbar } = this.props;
        data.id = userId;
        var submittedTags = JSON.parse(JSON.stringify(data));
        return actions.putUserTag(data).then(() => {
          this.setState({ currentUserTags: submittedTags });
          enqueueSnackbar(TranslatorUtil.t("User tags updated."), { variant: "success"});
        });
      }

      validateNewOption = (option) => {
        const { allUserTags } = this.state;
           this.setState({
            allUserTags: allUserTags.concat([option.label])
          });
        return true;
      }
      


      
        render() {
            const { userId, onClose } = this.props;
            const { comments, dataLoading, currentUserTags, allUserTags } = this.state;

            
            return <Dialog fullScreen onClose={onClose} open={true} 
            TransitionComponent={Transition}
            classes={{
              paper: "dialogPaperWithForm"
            }}
            >
              <AppBar style={{position:"relative"}}>
          <Toolbar  className="fullscreenDialogWrapBar">
            
            <Typography style={{
              flex: 1,
            }} variant="h5">
            {TranslatorUtil.t("User profile")}
            </Typography>
            <IconButton color="inherit" onClick={() => onClose()} aria-label={TranslatorUtil.t("close")}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent className="dialogFormContent">
          <div className="narrowMaxMediumWidthPart gridItem sectionVerticalPaddingItem">
          {
            userId ? <UserInfo userId={userId} withDialog={false} closeDialog={() => onClose()} /> : TranslatorUtil.t("User not found")
          }
  
          { dataLoading ? <div><ComponentLoading /></div> : 
            <div>
              {
                AccessUtil.canEditUserTags(userId) ? <UserTagsForm 
                initialValues={currentUserTags}
                userTags={allUserTags.map(x => ({
                  label: x,
                  value: x
                }))}
                validateNewOption={this.validateNewOption}
                onCancel={onClose}
                proceedSubmit={this.handleSubmitTags}
              /> : null
              }
              {
                AccessUtil.canEditUserComments(userId) ? <UserCommentForm 
                onCancel={onClose}
                proceedSubmit={this.handleSubmitComment}
              /> : null
              }
              {
                AccessUtil.canViewUserComments(userId) ? <CommentsList items={comments} /> : null
              }
              
            
            </div>
          }
          </div>
          
          
          
        </DialogContent></Dialog>
        }
}


function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    profiles: state.profiles,
    companyUsersTags: state.userTags
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...userCommentsActions,
        ...userTagsActions
      },
      dispatch
    )
  };
}


export default withRouter(withSnackbar(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(UserDialog))));

