import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import { List } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import ArrayUtil from '../../utils/ArrayUtil';
import MessageItem from './MessageItem';
import { Alert } from '@material-ui/lab';
import TranslatorUtil from '../../utils/TranslatorUtil';
import AccessUtil from '../../utils/projectBased/AccessUtil';
import objectPath from 'object-path';
import UsersUtil from '../../utils/projectBased/UsersUtil';


class MessagesList extends React.Component {



  renderReplies = (message,replies) => {
    const { likes, currentUserId, onLikeDislike, onMessageReply, projectId, isLocked } = this.props;
    return replies.filter(x => objectPath.get(x,'replyToMessageId') === message.id).length ? 
              <React.Fragment><List component="div" className="replies" disablePadding>
                 {
                   replies.filter(x => objectPath.get(x,'replyToMessageId') === message.id).map((childMessage, childIndex) => <React.Fragment key={childMessage.id}><MessageItem 
                   projectId={projectId}
                   disableAvatarActions={false}
                   disableReactions={isLocked || UsersUtil.getCurrentUserUid() === childMessage.byUserId}
                   key={childIndex}
                   message={childMessage} 
                   likes={likes} 
                   currentUserId={currentUserId} 
                   onLikeDislike={onLikeDislike}
                   onMessageReply={onMessageReply}
                   onReplyText=""
                   />
                   {this.renderReplies(childMessage,replies)}
                    </React.Fragment>
                  )
                 }
               </List>
               
               </React.Fragment> : null;
  }
  

  render() {
    const { items, oneSubmitted, likes, currentUserId, onLikeDislike, onMessageReply, projectId, isLocked } = this.props;

    var sortedItems = items || [];
    sortedItems = ArrayUtil.sortByDateCreated(items, true);
    var sortedReplies = sortedItems.filter(x => objectPath.has(x,'replyToMessageId'));


    return (<div className={"narrowMaxMediumWidthPart"}>
      {oneSubmitted || isLocked || AccessUtil.isAtLeastObserverForProject(projectId) ? (
        <List>

          {sortedItems.filter(x => !objectPath.has(x,'replyToMessageId')).map((message, index) =>
          <React.Fragment key={index}>
            <MessageItem 
              projectId={projectId}
              disableAvatarActions={false}
              message={message} 
              likes={likes} 
              disableReactions={isLocked || UsersUtil.getCurrentUserUid() === message.byUserId}
              currentUserId={currentUserId} 
              onLikeDislike={onLikeDislike} 
              onMessageReply={onMessageReply}
              onReplyText={TranslatorUtil.t("Reply")}
              />
              
              {this.renderReplies(message,sortedReplies)}
               
              
            
            </React.Fragment>)}
        </List>
        
      ) : <Alert severity="info">{TranslatorUtil.t("Please submit a message to see opinions of others")}</Alert>}</div>);
  }
}


export default withRouter(withStyles(styles, { withTheme: true })(MessagesList));