import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PageHeading from "../../../components/PageHeading";
import styles from "../../../theme/styles";
import ConversationForm from "../../../forms/conversation/form";
import * as projectActions from "../../../actions/project";
import * as discussionActions from "../../../actions/discussion";
import * as notificationActions from "../../../actions/notification";
import * as formNames from "../../../constants/forms";

import objectPath from "object-path";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import { withStyles } from "@material-ui/core/styles";
import HeadingBackBtn from "../../../components/buttons/HeadingBackBtn";
import SnackBarUtil from "../../../utils/SnackBarUtil";
import ComponentLoading from "../../../components/ComponentLoading";
import { change } from "redux-form";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import UrlBuilderUtil from "../../../utils/projectBased/UrlBuilderUtil";
import DiscussionIntro from "../../../components/discussion/DiscussionIntro";
import MessagesList from "../../../components/messages/MessagesList";
import {
  FormControlLabel,
  Checkbox,
  Grid,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import ArrayUtil from "../../../utils/ArrayUtil";
import AccessUtil from "../../../utils/projectBased/AccessUtil";
import EmailUtil from "../../../utils/projectBased/EmailUtil";
import CompanyUtil from "../../../utils/projectBased/CompanyUtil";
import UsersUtil from "../../../utils/projectBased/UsersUtil";
import { Alert, AlertTitle } from "@material-ui/lab";
import * as constants from "../../../constants/constants";
import EditIcon from "@material-ui/icons/Edit";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import InsertChartIcon from "@material-ui/icons/InsertChart";

const entityFormName = formNames.DISCUSSION_CONVERSATION_FORM_NAME;

class Discussion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discussion: null,
      discussionLoading: true,
      isAnonymous: false,
      replyToMessageId: null,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    var discussionId = UrlBuilderUtil.getRequestedParam(match, "discussionId");
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");
    Promise.all([
      this.mountProject(projectId),
      this.mountDiscussion(projectId, discussionId),
      this.listenDiscussion(projectId, discussionId),
    ]).then(() => {
      setTimeout(() => {
        var scrollTo = document.getElementById(
          objectPath.get(window, "location.hash", "").replace("#", "")
        );
        console.log("scrollTo", scrollTo);
        if (scrollTo) {
          scrollTo.scrollIntoView();
        }
      }, 1000);

      return this.setState({
        discussionLoading: false,
      });
    });
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.flushDiscussionMessages();
    actions.flushDiscussionLikes();
  }

  mountProject = (projectId) => {
    const { actions, selectedProject } = this.props;
    if (
      !selectedProject ||
      (selectedProject && selectedProject.id !== projectId)
    ) {
      return actions.getProject(projectId);
    } else {
      return Promise.resolve();
    }
  };

  mountDiscussion = (projectId, discussionId) => {
    const { actions } = this.props;
    const { discussion } = this.state;
    const _this = this;
    if (projectId && discussionId) {
      if (!discussion || (discussion && discussion.id !== discussionId)) {
        AccessUtil.isAtLeastRespondentForProject(projectId, true);
        return actions
          .getDiscussion(projectId, discussionId)
          .then((response) => {
            return _this.setState({
              discussion: objectPath.get(response, "data"),
            });
          });
      } else {
        return Promise.resolve();
      }
    } else {
      return new Promise((resolve) => {
        _this.setState(
          {
            discussion: null,
          },
          resolve
        );
      });
    }
  };

  listenDiscussion = (projectId, discussionId) => {
    const { actions } = this.props;
    if (projectId && discussionId) {
      actions.listenDiscussionMessages(projectId, discussionId);
      actions.listenDiscussionLikes(projectId, discussionId);
    }
  };

  onLikeDislike = (data, likesBtn = true) => {
    const { actions, match, userInfo } = this.props;
    console.log("onLikeDislike", data);
    var userId = objectPath.get(userInfo, "firebase.uid");
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");
    var discussionId = UrlBuilderUtil.getRequestedParam(match, "discussionId");

    var attrName = likesBtn ? "likes" : "dislikes";

    if (!objectPath.get(data, attrName, []).includes(userId)) {
      data[attrName] = ArrayUtil.addToArrayIfNotExist(data[attrName], userId);
    } else {
      data[attrName] = ArrayUtil.removeFromArrayIfExist(data[attrName], userId);
    }
    //console.log('putDiscussionMessageLikes',data);
    return actions.putDiscussionMessageLikes(projectId, discussionId, data);
  };

  onMessageReply = (messageId) => {
    this.setState({
      replyToMessageId: messageId,
    });
  };

  handleResponse = (response, values) => {
    const { enqueueSnackbar } = this.props;

    if (!SnackBarUtil.isResponseError(response, enqueueSnackbar)) {
      console.log("handleResponse", response, values);
      this.sendReplyNotification(values);
      this.setState({
        replyToMessageId: null,
      });
    }
  };

  sendReplyNotification = (message) => {
    if (message.replyToMessageId) {
      const { discussion } = this.state;
      const { actions, selectedProject, messages } = this.props;
      const firstMessage = messages.find(
        (x) => x.id === message.replyToMessageId
      );
      console.log("sendReplyNotification", firstMessage);
      if (firstMessage) {
        const authorEmail = UsersUtil.getUserEmailFromStoreById(
          firstMessage.byUserId
        );
        if (authorEmail) {
          return actions.postNotification(
            EmailUtil.getDiscussionReactionEmailObject(
              CompanyUtil.getId(),
              selectedProject,
              discussion,
              firstMessage,
              message,
              authorEmail
            )
          );
        }
      }
    }
  };

  handleSubmit = (values) => {
    const { actions, match, userInfo } = this.props;
    const { discussion, isAnonymous, replyToMessageId } = this.state;
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");
    var discussionId = UrlBuilderUtil.getRequestedParam(match, "discussionId");
    values.byUserId = objectPath.get(userInfo, "firebase.uid");
    values.anonymous = isAnonymous;
    if (replyToMessageId) {
      values.replyToMessageId = replyToMessageId;
    }

    return discussion
      ? actions
          .postDiscussionMessage(projectId, discussionId, values)
          .then((response) => this.handleResponse(response, values))
      : Promise.resolve();
  };

  toggleAnonymity = () => {
    this.setState({
      isAnonymous: !this.state.isAnonymous,
    });
  };

  hasSubmitAtLeastOne = () => {
    const { messages, userInfo } = this.props;
    var userId = objectPath.get(userInfo, "firebase.uid");
    return ArrayUtil.isNonEmptyArray(messages) &&
      messages.findIndex((x) => x.byUserId === userId) > -1
      ? true
      : false;
  };

  render() {
    const {
      match,
      messages,
      userInfo,
      discussionLikes,
      selectedProject,
      history,
    } = this.props;
    const {
      discussion,
      discussionLoading,
      isAnonymous,
      replyToMessageId,
    } = this.state;
    const projectID = objectPath.get(selectedProject, "id", null);
    const discussionID = objectPath.get(discussion, "id", null);

    return (
      <div className="discussion-wrap">
        <PageHeading
          heading={discussion ? discussion.name : TranslatorUtil.t("Loading")}
          actions={
            <div>
              <Tooltip
                enterDelay={constants.TOOLTIP_CARDS_DELAY}
                title={TranslatorUtil.t("Project")}
              >
                <IconButton
                  aria-label={TranslatorUtil.t("Project")}
                  onClick={() =>
                    history.push(
                      UrlBuilderUtil.getProjectDashboard(projectID)
                    )
                  }
                >
                  <BusinessCenterIcon />
                </IconButton>
              </Tooltip>
              {selectedProject &&
              AccessUtil.isAtLeastModeratorForProject(projectID) ? (
                <React.Fragment>
                  <Tooltip
                    enterDelay={constants.TOOLTIP_CARDS_DELAY}
                    title={TranslatorUtil.t("Export")}
                  >
                    <IconButton
                      aria-label={TranslatorUtil.t("Export")}
                      onClick={() =>
                        history.push(
                          UrlBuilderUtil.getDiscussionExport(
                            projectID,
                            discussionID
                          )
                        )
                      }
                    >
                      <InsertChartIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    enterDelay={constants.TOOLTIP_CARDS_DELAY}
                    title={TranslatorUtil.t("Edit")}
                  >
                    <IconButton
                      aria-label={TranslatorUtil.t("Edit")}
                      onClick={() =>
                        history.push(
                          UrlBuilderUtil.getDiscussionEdit(
                            projectID,
                            discussionID
                          )
                        )
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              ) : null}
              <HeadingBackBtn
                redirectToUrl={UrlBuilderUtil.getProjectDashboard(
                  UrlBuilderUtil.getRequestedParam(match, "projectId")
                )}
              />
            </div>
          }
        />
        <Grid container>
          <Grid item xs={12} md={6} lg={7}>
            {discussion ? (
              <DiscussionIntro
                item={discussion}
                isExpanded={!this.hasSubmitAtLeastOne()}
                isLocked={
                  AccessUtil.isProjectLocked(selectedProject) ||
                  AccessUtil.isDiscussionLocked(discussion)
                }
              />
            ) : null}
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            {discussionLoading ? (
              <ComponentLoading />
            ) : (
              <MessagesList
                projectId={projectID}
                items={messages}
                likes={discussionLikes}
                currentUserId={objectPath.get(userInfo, "firebase.uid")}
                oneSubmitted={this.hasSubmitAtLeastOne()}
                isLocked={
                  AccessUtil.isProjectLocked(selectedProject) ||
                  AccessUtil.isDiscussionLocked(discussion)
                }
                onLikeDislike={
                  !AccessUtil.isObserverForProject(projectID, false)
                    ? this.onLikeDislike
                    : null
                }
                onMessageReply={
                  !AccessUtil.isObserverForProject(projectID, false)
                    ? this.onMessageReply
                    : null
                }
              />
            )}
            <div className="messageFormWrapSpacer"></div>
            {(AccessUtil.isRespondentForProject(projectID, false) ||
              AccessUtil.isAtLeastModeratorForProject(projectID, false)) && (
              <div className="messageFormWrap">
                {discussionLoading ? null : AccessUtil.isProjectLocked(
                    selectedProject
                  ) || AccessUtil.isDiscussionLocked(discussion) ? (
                  <div className={"narrowMaxMediumWidthPart"}>
                    <Alert severity="info">
                      <AlertTitle>
                        {TranslatorUtil.t("Discussion is closed")}
                      </AlertTitle>
                    </Alert>
                  </div>
                ) : (
                  <div className={"narrowMaxMediumWidthPart"}>
                    <ConversationForm
                      removeReplyTo={() =>
                        this.setState({ replyToMessageId: null })
                      }
                      replyToMessage={
                        replyToMessageId
                          ? messages.find((x) => x.id === replyToMessageId)
                          : null
                      }
                      likes={discussionLikes}
                      proceedSubmit={this.handleSubmit}
                      initialValues={null}
                    />
                    <FormControlLabel
                      className="px-2"
                      control={
                        <Checkbox
                          checked={isAnonymous}
                          onChange={this.toggleAnonymity}
                          value={isAnonymous}
                        />
                      }
                      label={TranslatorUtil.t("as anonym")}
                    />
                  </div>
                )}
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedProject: state.selectedProject,
  userInfo: state.userInfo,
  messages: state.messages,
  profiles: state.profiles,
  discussionLikes: state.discussionLikes,
});

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: function(field, value) {
    dispatch(change(entityFormName, field, value));
  },
  actions: bindActionCreators(
    {
      ...projectActions,
      ...discussionActions,
      ...notificationActions,
    },
    dispatch
  ),
});

export default withRouter(
  withSnackbar(
    withStyles(styles, { withTheme: true })(
      connect(mapStateToProps, mapDispatchToProps)(Discussion)
    )
  )
);
