import React from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import objectPath from "object-path";
import * as discussionActions from '../../../actions/discussion';
import * as projectActions from '../../../actions/project';
//import * as constants from '../../constants/constants';
import ComponentLoading from "../../../components/ComponentLoading";
import Masonry from 'react-masonry-component';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from "../../../theme/styles";
import ArrayUtil from "../../../utils/ArrayUtil";
import { Button, Grid, Hidden, Typography } from "@material-ui/core";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import UrlBuilderUtil from "../../../utils/projectBased/UrlBuilderUtil";
import FormatterUtil from "../../../utils/FormatterUtil";
import PageHeading from "../../../components/PageHeading";
import AppCard from "../../../components/AppCard";
import HeadingAddBtn from "../../../components/buttons/HeadingAddBtn";
import DiscussionCardContent from "../../../components/discussion/DiscussionCardContent";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import SnackBarUtil from "../../../utils/SnackBarUtil";
import { withSnackbar } from "notistack";
import AccessUtil from "../../../utils/projectBased/AccessUtil";
import LockIcon from '@material-ui/icons/Lock';
import { IconButton, Tooltip } from '@material-ui/core';
import * as constants from '../../../constants/constants';
import EditIcon from '@material-ui/icons/Edit';
import PageviewIcon from '@material-ui/icons/Pageview';
import GroupIcon from '@material-ui/icons/Group';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ProjectPreviewCard from "../../../components/project/ProjectPreviewCard";
import CompanyUtil from "../../../utils/projectBased/CompanyUtil";

const entityName = "Discussion";

class ProjectDashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      discussions: [],
      dataLoading: true,
      expandedCardIds: [],
    }
  }


  renderConfirmationDialog = () => {
    const { itemToDelete } = this.state;
    if(itemToDelete){
      return (<ConfirmDialog 
      message={TranslatorUtil.t('Please confirm you wish to remove ')+FormatterUtil.camelToNormalForm(entityName)+' '+itemToDelete.name}
      onClose={() => this.onDeleteClicked(null)}
      onConfirm={() => this.handleDiscussionDelete(itemToDelete.id)}/>)
    }
  }

  onDeleteClicked = (item) => {
    this.setState({
      itemToDelete: item
    });
  }

  handleDiscussionDelete = (id) => {
    const { actions, selectedProject } = this.props;
    return selectedProject ? actions.deleteDiscussion(selectedProject.id, id).then(response => this.handleResponse(response)) : null;
  }

  

  handleResponse = response => {
    
    const { enqueueSnackbar } = this.props;
    this.onDeleteClicked(null);//hide confirm
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      SnackBarUtil.showSuccessMessage(response, enqueueSnackbar);
      
      //reload
      this.mountItems(true);
    }
  }

  mountDiscussions = (projectID) => {
    const _this = this;
    const { actions } = this.props;
    AccessUtil.isAtLeastRespondentForProject(projectID,true);
    actions.getDiscussions(projectID).then((response) => {
      return _this.setState({
        discussions: response.data
      });
    });
  }

  finishLoading = () => {
    return this.setState({
      dataLoading: false
    });
  }

  mountItems = () => {
    
    const { selectedProject, actions, match } = this.props;
    var projectID = objectPath.get(match,'params.projectId');
    if(selectedProject && selectedProject.id === projectID){
      this.mountRelatedItems();
    } else {
      if(projectID){
        actions.getProject(projectID).then(() => this.mountRelatedItems());
      }
      
    }
    
  }

  mountRelatedItems = () => {
    const { match } = this.props;
    const _this = this;
    var projectID = objectPath.get(match,'params.projectId');
    if(projectID){
      Promise.all([
        _this.mountDiscussions(projectID)
      ]).then(()=>{ _this.finishLoading() }).catch(function(err) {
        _this.finishLoading()
      });
    }
    
  }


  componentDidMount() {
    this.mountItems();
  }


  toggleCardById = (id) => {
    const { expandedCardIds } = this.state;
    if(expandedCardIds.includes(id)){
      this.setState({expandedCardIds: ArrayUtil.removeFromArrayIfExist(expandedCardIds, id)});
    } else {
      this.setState({expandedCardIds: ArrayUtil.addToArrayIfNotExist(expandedCardIds, id)});
    }
  }

  render() {
    const { dataLoading, showShowMoreBtn, discussions, itemToDelete } = this.state;
    const { classes, selectedProject, match, history } = this.props;

    var projectID = objectPath.get(match,'params.projectId');
    

    const masonryOptions = {
      itemSelector: '.masonry-item',
      columnWidth: '.masonry-item-sizer',
      percentPosition: true,
      horizontalOrder: true
    };

    return <div className="project-dashboard-wrap">
      <PageHeading heading={TranslatorUtil.t(FormatterUtil.camelToNormalForm(entityName)+"s") + (selectedProject ? " - "+selectedProject.name : "")} actions={selectedProject ? 
        <React.Fragment>
        <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Preview")}>
                                    <IconButton  aria-label={TranslatorUtil.t("Preview")} onClick={() => history.push(UrlBuilderUtil.getProjectPreview(projectID))}>
                                        <PageviewIcon />
                                    </IconButton>
                                </Tooltip>
        {AccessUtil.isAtLeastModeratorForProject(projectID) ? <React.Fragment>
                                
          <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Notify users")}>
                                    <IconButton  aria-label={TranslatorUtil.t("Notify users")} onClick={() => history.push(UrlBuilderUtil.getProjectNotifyUsers(projectID))}>
                                        <NotificationsActiveIcon />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Users")}>
                                    <IconButton  aria-label={TranslatorUtil.t("Users")} onClick={() => history.push(UrlBuilderUtil.getProjectUsersEdit(projectID))}>
                                        <GroupIcon />
                                    </IconButton>
                                </Tooltip>
                                    <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Edit")}>
                                  <IconButton  aria-label={TranslatorUtil.t("Edit")} onClick={() => history.push(UrlBuilderUtil.getProjectEdit(projectID))}>
                                      <EditIcon />
                                  </IconButton>
                                  </Tooltip>
        </React.Fragment> : null }
                                
                                {AccessUtil.isProjectLocked(selectedProject) ? <LockIcon color="disabled" /> : AccessUtil.isAtLeastModeratorForProject(projectID) ? <HeadingAddBtn redirectToUrl={UrlBuilderUtil.getDiscussionCreate(projectID)} /> : null }
                                </React.Fragment> : null} />
      {dataLoading && discussions.length < 1 ? <ComponentLoading /> :
        <div>
          <Grid container>
                <Hidden smDown>
                <Grid item xs={12} md={6} lg={4}>
                <ProjectPreviewCard 
                company={CompanyUtil.get()}
                hideTerms={true}
                project={selectedProject} />
                </Grid>
                </Hidden>
                <Grid item xs={12} md={6} lg={8}>
                <Masonry
                  //ref={function(c) {this.masonry = this.masonry || c.masonry;}.bind(this)}
                  className={'masonry-grid-wrap'} // default ''
                  options={masonryOptions} // default {}
                >
                  <div className="masonry-item-sizer"></div>
                  {ArrayUtil.sortByDateCreated(discussions, false).map((item, index) => {

                      return <div className="masonry-item" key={index}>
                        
                        <AppCard>
                          <DiscussionCardContent index={index+1} project={selectedProject} item={item} onDeleteClick={this.onDeleteClicked} />
                        </AppCard>
                      </div>;
                  })}
                </Masonry>
                </Grid>
          
          </Grid>
          { itemToDelete ? this.renderConfirmationDialog() : null}
          { dataLoading &&  discussions.length > 0 ? <ComponentLoading /> : null }
          { !dataLoading &&  discussions.length < 1 ? <div className={classnames(classes.textCenter, classes.contentWithoutHeader)}><Typography variant="body2" className="muted">{TranslatorUtil.t("No results")}</Typography></div> : null }
          { !dataLoading && showShowMoreBtn ? <div className="gridItem"><br/><br/><Button size="large" color="primary" fullWidth={true} variant="outlined" onClick={this.showMore}>{TranslatorUtil.t("Show More")}</Button></div> : null}
          
          </div>}
    </div>
  }
}


function mapStateToProps(state) {
  return {
    selectedProject: state.selectedProject
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...projectActions,
        ...discussionActions,
      },
      dispatch
    )
  };
}


export default withRouter(withSnackbar(withStyles(styles, { withTheme: true })(connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDashboard))));
