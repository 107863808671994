import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PageHeading from "../../../components/PageHeading";

import ProjectForm from "../../../forms/project/form";
import * as projectActions from "../../../actions/project";
import * as formNames from "../../../constants/forms";
import * as fileStorageActions from "../../../actions/fileStorage";
import objectPath from 'object-path';
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";

import HeadingBackBtn from "../../../components/buttons/HeadingBackBtn";
import SnackBarUtil from '../../../utils/SnackBarUtil';
import ComponentLoading from "../../../components/ComponentLoading";
import { change } from 'redux-form';
import FormatterUtil from "../../../utils/FormatterUtil";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import DataUtil from "../../../utils/DataUtil";
import { Box, Button } from "@material-ui/core";
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Alert } from "@material-ui/lab";
import AccessUtil from "../../../utils/projectBased/AccessUtil";

const entityName = "Project";
const entityListUrl = "/projects/";
const entityFormName = formNames.PROJECT_FORM_NAME;


class EditProject extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      item: null,
      dataLoading: true
    }
  }

  componentDidMount(){
    Promise.all([
      this.mountItem()
    ]).then(() =>
      this.setState({
        dataLoading: false
      })
      );
  }


  mountItem(){
    const { actions, actionType, match } = this.props;
    const _this = this;
    var itemId = objectPath.get(match, "params.projectId");
    if(actionType === "edit" && itemId){
      AccessUtil.isAtLeastModeratorForProject(itemId,true);
      return actions['get'+entityName](itemId).then( response => {
        return _this.setState({
          item: objectPath.get(response, "data"),
        })
      });
    } else {
      AccessUtil.isCompanyAdmin(true);
      return new Promise((resolve) => {
        
        _this.setState({
          item: null,
        }, resolve);
      });
    }
  }

  uploadImages = (values) => {
    var _this = this;
    var filteredNewImages = values && values.images && values.images.length ? values.images.filter(x => x.base64String && x.base64String.length) : [];
    if(filteredNewImages && filteredNewImages.length){
      _this.setState({uploadingImages:true});
      return Promise.all(filteredNewImages.map(x => _this.uploadImage(x))).then(() => _this.setState({uploadingImages:false}));
    } else {
      return Promise.resolve();
    }
  }

  uploadImage = (file) => {
    const { actions, enqueueSnackbar } = this.props;
    var _this = this;
    enqueueSnackbar(TranslatorUtil.t("Uploading")+" "+file.name, {
      variant: "info"
    });
    return actions.postFile(file).then(response => {
      //console.log('uploadImages');
      
      return response.data.ref.getDownloadURL().then(function(downloadUrl) {
        file.downloadUrl = downloadUrl;
        return _this.handleResponse(response, file)
      });
    });
  }

  handleResponse = (response, values) => {
    const { enqueueSnackbar, actionType } = this.props;
    
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      var isFile = values.folder && values.folder.length;
      var successMessage = "\""+values.name+"\" "+ (isFile? TranslatorUtil.t("uploaded") : " "+(actionType === "create" ? TranslatorUtil.t("added") : TranslatorUtil.t("updated")));
      enqueueSnackbar(successMessage, {
        variant: "success"
      });
      if(!isFile){
        this.finishAction();
      }
    }
  }



  finishAction = () => {
    const { history } = this.props;
    history.push(entityListUrl)
  }

  handleSubmit = values => {
    const { actions, actionType, match } = this.props;
    let action = actions['post'+entityName];
    if(actionType !== "create") {
      action = actions['put'+entityName];
      values.id = objectPath.get(match, 'params.projectId');
    }
    this.uploadImages(values).then(() => {
      return action(DataUtil.removeImagesBase64(values,'images'))
        .then(response => {
          this.handleResponse(response, values);
        });
    });
    //has to return promise so submitting flag works!
    
  };

  toggleLock = () => {
    const { actions, match, enqueueSnackbar } = this.props;
    var itemId = objectPath.get(match, 'params.projectId');
    return actions[(this.isLocked() ? 'unlock' : 'lock')+entityName](itemId).then(() => {
      this.mountItem();
      enqueueSnackbar(this.isLocked() ? TranslatorUtil.t("Project unlocked") : TranslatorUtil.t("Project locked"), {
        variant: "info"
      });
    });
    
  }

  isLocked = () => {
    const { item } = this.state;
    return AccessUtil.isProjectLocked(item);
  }


  render() {
    const { actionType } = this.props;
    const { item, dataLoading, uploadingImages } = this.state;
    

    return (
      <div>
        <PageHeading heading={(actionType === "create" ? TranslatorUtil.t("New") : TranslatorUtil.t("Update"))+" "+TranslatorUtil.t(FormatterUtil.camelToNormalForm(entityName))} actions={
          <div>
            <HeadingBackBtn redirectToUrl={entityListUrl} />
        </div>
        } />

      { dataLoading ? <ComponentLoading /> : 
      <React.Fragment>
        
      
      <ProjectForm 
        proceedSubmit={this.handleSubmit} 
        initialValues={item}
        isLocked={this.isLocked()}
        submittingImages={uploadingImages}

      />
      {
          actionType !== "create" ? <Box pt={4} className="narrowMaxMediumWidthPart">
        <Box py={2}>
      <Alert severity="warning">
      {this.isLocked() ? TranslatorUtil.t("Unlock") : TranslatorUtil.t("Lock")} {TranslatorUtil.t("project and its discussions for all respondents manually")}
      </Alert>
      </Box>
      <Button variant="contained" startIcon={this.isLocked() ? <LockOpenOutlinedIcon /> : <LockOutlinedIcon color="error" />} onClick={this.toggleLock}>{this.isLocked() ? TranslatorUtil.t("Unlock") : TranslatorUtil.t("Lock")  } {TranslatorUtil.t("manually")}</Button>
      </Box>
       : null
      }

      </React.Fragment> }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = dispatch =>
({
  changeFieldValue: function(field, value) {
    dispatch(change(entityFormName, field, value))
  },
  actions: bindActionCreators(
    {
      ...projectActions,
      ...fileStorageActions
    },
    dispatch
  )
});


export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditProject)
));