import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import firestoreApi from '../firebase.js';
import FirestoreCollectionUtil from "../utils/FirestoreCollectionUtil";
import * as collections from '../constants/firebaseCollections';
import CompanyUtil from "../utils/projectBased/CompanyUtil";
import DateUtil from "../utils/DateUtil";

const collectionName = collections.PROJECTS;


export function getProjects() {
  return function(dispatch) {
    console.log('getProjects called');
    return firestoreApi.collection(CompanyUtil.getCollectionPathWithCompanyPrefix(collectionName)).get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_PROJECTS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_PROJECTS))
      );
  };
}


export function listenProjects() {
  return function(dispatch) {
    console.log('getProjects called');

    
    return firestoreApi.collection(CompanyUtil.getCollectionPathWithCompanyPrefix(collectionName))
    // .where("archived", "==", true)
    //   .where("deleted", "==", false)
    //   .orderBy("displayName")
    .onSnapshot()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_PROJECTS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_PROJECTS))
      );
  };
}

  export function postProject(data) {
    return function(dispatch) {
      console.log('postProject');
      data.createdTime = DateUtil.timestamp();
      data.updatedTime = DateUtil.timestamp();
      return firestoreApi.collection(CompanyUtil.getCollectionPathWithCompanyPrefix(collectionName)).add(data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_PROJECT)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_PROJECT))
        );
    };
  }

  export function putProject(data) {
    return function(dispatch) {
      console.log('putProject');
      data.updatedTime = DateUtil.timestamp();
      return firestoreApi.collection(CompanyUtil.getCollectionPathWithCompanyPrefix(collectionName)).doc(data.id).set(FirestoreCollectionUtil.removeMetaData(data))
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PUT_PROJECT)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.PUT_PROJECT))
        );
    };
  }

  export function lockProject(id) {
    return function(dispatch) {
      console.log('lockProject');
      return firestoreApi.collection(CompanyUtil.getCollectionPathWithCompanyPrefix(collectionName))
      .doc(id)
      .update({
        locked : true,
        lockDate : DateUtil.backendDateFormat(),
        lockTime : DateUtil.backendTimeFormat(),
        updatedTime : DateUtil.timestamp(),
      })
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.LOCK_PROJECT)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.LOCK_PROJECT))
        );
    };
  }

  export function unlockProject(id) {
    return function(dispatch) {
      console.log('unlockProject');
      return firestoreApi.collection(CompanyUtil.getCollectionPathWithCompanyPrefix(collectionName)).doc(id)
      .update({locked:false,lockDate:null,lockTime:null,updatedTime:DateUtil.timestamp()})
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.UNLOCK_PROJECT)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.UNLOCK_PROJECT))
        );
    };
  }

  export function getProject(id) {
    return function(dispatch) {
      console.log('getProject');
      return firestoreApi.collection(CompanyUtil.getCollectionPathWithCompanyPrefix(collectionName)).doc(id).get()
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionItemData(response), types.GET_PROJECT)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_PROJECT))
        );
    };
  }

 export function deleteProject(id) {
    return function(dispatch) {
      console.log('deleteProject');
      return firestoreApi.collection(CompanyUtil.getCollectionPathWithCompanyPrefix(collectionName)).doc(id).delete()
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.DELETE_PROJECT)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.DELETE_PROJECT))
        );
    };
}
